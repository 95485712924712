import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  Avatar,
  Typography,
  Stack,
  Paper,
  Container,
  TextField,
  Box,
  Button,
  CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, MenuItem, Select, Tooltip, Chip
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Star from '../components/star';
import Star1 from '../components/completedcardstar';
import Divider from '@mui/material/Divider';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CircularIndeterminate from '../components/loading';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import TablePagination from '@mui/material/TablePagination';
import useLogout from '../hooks/logouthook';
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import { CircularProgress } from "@mui/material";
import StarIcon from '@mui/icons-material/Star'
import RecordLink from './complete_class/RecordLink';
import DownloadVideo from './complete_class/DownloadVideo';



const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function CompletedClassForm() {
  const { t_id } = useParams();
  const [classData, setClassData] = useState([]);
  const studentID = localStorage.getItem('studentID');
  const [feedbacks, setFeedback] = useState({});
  const [stars, setStars] = useState({});
  const [ssch_id, setSsch_id] = useState("");
  const [errors, setErrors] = useState('');
  const [topuperrors, setTopupErrors] = useState('');
  const [loading1, setLoading1] = React.useState(true);
  const [teacherID, setTeacherID] = useState("");
  const [errors1, setErrors1] = useState('');
  const [teacher, setTeacher] = useState([]);
  const [date, setDate] = useState("");
  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);
  const [amount, setAmount] = useState('');
  const [openAccountBalanceModal, setOpenAccountBalanceModal] = useState(false);
  const [accBalance, setAccBalance] = useState({ totalAmount: 0, acc_balance: 0 });
  const [schedule_date, setSchedule_date] = useState("");
  const [availableDate, setAvailableDate] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [currency, setCurrency] = useState("");
  const [maxHour, setMaxHour] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const itemsPerPage = 3;
  const { handleLogout } = useLogout();
  const navigate = useNavigate();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordOpen, setRecordOpen] = useState(false)
  const [recordLinks, setRecordLinks] = useState([])
  const [downloadOpen, setDownloadOpen] = useState(false)

  const theme = useTheme()

  const handleNavigate = (item) => {
    // Navigate to the dynamic URL with the teacher's name and ID
    navigate(`/English_Teacher_${item.t_id}-${item.t_fname}.html`);
  };


  const smAbove = useMediaQuery(theme.breakpoints.down("sm"));

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleButtonClick = (t_id) => {
    handleOpenTimeSlotModal(t_id);
  };

  const handlefeedback = (event, ssch_id) => {
    setFeedback({ [ssch_id]: event.target.value });
    setErrors({ [ssch_id]: "" });
  };

  const handlestars = (newValue, ssch_id) => {
    setStars({ [ssch_id]: newValue });
    setErrors({ [ssch_id]: "" });
  };

  useEffect(() => {
    fetchClassData();
  }, [studentID, page]);

  const fetchClassData = async () => {
    setLoading1(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/completed_classes`, {
        method: 'POST',
        body: JSON.stringify({
          studentID,
          page,
          itemsPerPage,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchClassData();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setClassData(getData.data);
      setTotalItems(getData.totalItems);
      setCurrency(getData.currency);
      setLoading1(false);

    } catch (err) {
      setClassData([]);
      setSsch_id("");
    }
  };

  const handleSubmitRate = async (event, item) => {
    event.preventDefault();

    if (!stars[item.ssch_id]) {
      setErrors({ [item.ssch_id]: "Please rate" });
      return;
    }

    Swal.fire({
      title: `Rate Confirmation`,
      text: `Are you sure you want to submit this rating?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00669e',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      reverseButtons: true,
      customClass: {
        container: 'custom-swal-container' // Add a custom class to the container
      }
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/submitRatingAndFeedback`, {
            method: 'POST',
            body: JSON.stringify({
              ssch_id: item.ssch_id,
              t_id: item.t_id,
              stars: stars[item.ssch_id],
              feedback: feedbacks[item.ssch_id] || ""
            }),
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }
          });

          if (!response.ok) {
            throw new Error(`HTTP error: Status ${response.status}`);
          }

          if (response.status === 403 || response.status === 401) {
            let refreshToken = '';
            if (typeof localStorage !== 'undefined') {
              refreshToken = window.localStorage.getItem('refreshToken');
            }

            try {
              const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  refreshToken: refreshToken,
                }),
              });

              if (tokenResponse.status === 200) {
                const tokendata = await tokenResponse.json();
                window.localStorage.setItem('token', tokendata.accessToken);
                token = tokendata.accessToken;


                return handleSubmitRate(event, item);
              } else {
                handleLogout();
              }
            } catch (error) {
              console.error('Error refreshing token:', error);
              handleLogout();
            }

            return;
          }

          const data = await response.json();

          if (data.message === 'Rating and feedback updated successfully') {
            setStars({ [item.ssch_id]: "" });
            setFeedback({ [item.ssch_id]: "" });
            fetchClassData();
          } else {
            setErrors({ [item.ssch_id]: data.error });
          }

        } catch (error) {
          console.error("Error fetching data: ", error);
          setErrors({ [item.ssch_id]: "Error submitting rating and feedback" });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          icon: 'error',
          confirmButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        })
      }
    })

  };

  const fetchTeacher = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacherData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          t_id: t_id
        })
      });
      console.log(t_id);
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;

            return fetchTeacher();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      const getData = await response.json();
      setTeacher(getData.Data[0]);
    } catch (err) {
      console.error('Error fetching teacher data:', err);
    }
  };


  // Time Slot Modal

  const handleOpenTimeSlotModal = (id) => {
    setOpenTimeSlotModal(true);
    fetchAvailableDate(id);
    fetchTeacher(id);
    setErrors("");
    setErrors1("");
  };

  const handleCloseTimeSlotModal = () => {
    setOpenTimeSlotModal(false);
    setDate("");
    setSelectedSlots([]);
    setErrors("");
    setErrors1("");
  };


  // AccountBalance Modal

  const newAccountBalance = accBalance.acc_balance - accBalance.totalAmount;

  const handleopenAccountBalanceModal = async () => {
    if (!date) {
      setErrors("Please select a date");
    } else if (selectedSlots.length === 0) {
      setErrors1('Please select time slots that you want.');

    } else {
      try {
        await fetchAccBalance();
        setOpenAccountBalanceModal(true);
        setOpenTimeSlotModal(false);
        setDate("");

      } catch (error) {
        console.error(error);
      }

    }
  };

  const handleCloseAccountBalanceModal = () => {
    setOpenAccountBalanceModal(false);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    fetchAvailableTime(selectedDate);
    setSelectedSlots([]);
    setErrors("");
  };

  const handleTimeChange = () => {
    setErrors1("");
  };

  const handleChangeSheduleDate = () => {
    setOpenAccountBalanceModal(false);
    setOpenTimeSlotModal(true);
  };

  useEffect(() => {
    if (t_id) {
      fetchAvailableDate(t_id);
    }
  }, [t_id]);

  const fetchAvailableDate = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/book_date/${t_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchAvailableDate(t_id);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      const getData = await response.json();
      setAvailableDate(getData.Data);
      setTeacherID(getData.t_id);
    } catch (err) {
      console.error('Error fetching available dates:', err);
    }
  };

  const fetchAvailableTime = async (schedule_date) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/timeslots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          t_id: teacherID,
          schedule_date
        })
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchAvailableTime(schedule_date);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setAvailableTime(getData.Data);
      setMaxHour(getData.maxHour);
      setSchedule_date(schedule_date);
    } catch (err) {
      console.error('Error fetching available time:', err);
    }
  };

  const handleSelectTimeSlot = (timeSlot) => {
    setErrors("");
    const slotIndex = selectedSlots.findIndex(slot => slot.start_time === timeSlot.start_time && slot.end_time === timeSlot.end_time);
    let newSelectedSlots = [...selectedSlots];

    if (slotIndex > -1) {
      newSelectedSlots.splice(slotIndex, 1); // Deselect if already selected
    } else {
      newSelectedSlots.push(timeSlot); // Select if not already selected
    }

    // Check if the selected slots are within the allowed range
    newSelectedSlots.sort((a, b) => new Date(`1970-01-01T${a.start_time}`) - new Date(`1970-01-01T${b.start_time}`));

    let validSelection = true;

    for (let i = 0; i < newSelectedSlots.length; i++) {
      let consecutiveHours = 0;
      for (let j = i; j < newSelectedSlots.length; j++) {
        const slotStart = new Date(`1970-01-01T${newSelectedSlots[j].start_time}`);
        const slotEnd = new Date(`1970-01-01T${newSelectedSlots[j].end_time}`);

        if (j > i) {
          const previousSlotEnd = new Date(`1970-01-01T${newSelectedSlots[j - 1].end_time}`);
          const gapHours = (slotStart - previousSlotEnd) / (1000 * 60 * 60);

          if (gapHours !== 0) {
            break;
          }
        }

        consecutiveHours += (slotEnd - slotStart) / (1000 * 60 * 60);
        if (consecutiveHours > maxHour) {
          validSelection = false;
          break;
        }
      }
      if (!validSelection) {
        break;
      }
    }

    if (validSelection) {
      setSelectedSlots(newSelectedSlots);
    } else {
      setErrors("You cannot select more than 2 consecutive hours.");
    }

  };

  const fetchAccBalance = async () => {
    try {
      const storedStudentID = localStorage.getItem('studentID');

      if (!storedStudentID) {
        throw new Error("Student ID is not found in session storage");
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/schedule_class`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          selectedSlots
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }
      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return fetchAccBalance();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      } else {
        const getData = await response.json();

        setAccBalance(getData);
        setCurrency(getData.currency);
      }


    } catch (err) {
      console.error("Error fetching account balance: ", err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const storedStudentID = localStorage.getItem('studentID');
    const booked_date = new Date();

    try {
      const balanceResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stu-accountbalance`, {
        method: 'POST',
        body: JSON.stringify({

        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (balanceResponse.status === 403 || balanceResponse.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {

          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;
            return handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }
        setLoading(false);
        return;
      }

      if (balanceResponse.status !== 200) {
        throw new Error(`HTTP error: Status ${balanceResponse.status}`);
      }

      const balanceData = await balanceResponse.json();

      console.log("balance", balanceData.balance)

      setAccBalance((prev) => ({
        ...prev,
        acc_balance: balanceData.balance
      }));

      if (balanceData.balance < accBalance.totalAmount) {
        setOpenAccountBalanceModal(false);

        Swal.fire({
          icon: 'error',
          title: 'Insufficient Balance',
          text: 'Your account balance is insufficient to complete this booking. Please Top-Up your account.',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'Cancel',
          showCancelButton: true,
          cancelButtonText: 'Top-Up',
          cancelButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container'
          }

        }).then((result) => {
          if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/account_topup';
          }
        });
        setLoading(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/confirm_booking`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          teacher: teacher,
          selectedSlots,
          totalAmount: accBalance.totalAmount,
          acc_balance: accBalance.acc_balance,
          booked_date: booked_date.toISOString(),
          newAccountBalance,
          schedule_date: schedule_date
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });


      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmit();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        if (response.status === 400) {
          setOpenAccountBalanceModal(false);
          const data = await response.json();

          // Display the SweetAlert based on the error returned
          Swal.fire({
            icon: 'error',
            title: 'Booking Failed',
            text: data.error || 'An error occurred during the booking process.',
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK'
          });

          return;
        }
        setLoading(false);
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();

      if (data.message === 'Class booked successfully!') {
        setOpenAccountBalanceModal(false);
        setErrors('');

        const scheduleDate = schedule_date;
        // const startTime = selectedSlots[0].start_time; 
        // const endTime = selectedSlots[selectedSlots.length - 1].end_time;


        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          {selectedSlots.map((slot, index) => (
            <Typography key={index}>
              {slot.start_time} - {slot.end_time}
            </Typography>
          ))}
        </Grid>;


        const slotsHtml = selectedSlots.map((slot) => {
          return `<p><strong>Schedule Time:</strong> ${slot.start_time} - ${slot.end_time}</p>`;
        }).join('');

        Swal.fire({
          icon: 'success',
          title: 'Class booked successfully!',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'Class Details',
          showCancelButton: true,
          cancelButtonText: 'Go to dashboard',
          cancelButtonColor: '#101847',
          customClass: {
            container: 'custom-swal-container'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/pendingApproval_class';
          } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/dashboard';
          }
        });
        setLoading(false);
      }


    } catch (error) {
      setOpenAccountBalanceModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Booking Failed',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonColor: '#00669e',
        confirmButtonText: 'OK'
      });
      setLoading(false);
    }
  };



  // Top Up Modal

  const handleOpenTopUpModal = (newAccountBalance) => {
    setAmount(newAccountBalance);
    setOpenAccountBalanceModal(false);
    setOpenTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setOpenTopUpModal(false);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setErrors("")


    if (isNaN(value)) {
      setErrors("Please enter a valid amount");
    } else {
      setErrors('');
    }
    setAmount(value);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleSubmitPay(event);
    }
  };

  const handleSubmitPay = async (event) => {
    event.preventDefault();

    const storedStudentID = localStorage.getItem('studentID');

    setTopupErrors("");

    if (!amount) {
      setTopupErrors("Please add an amount");
      return;
    }
    const numericAmount = parseInt(amount, 10);
    if (numericAmount < 1000 || numericAmount > 15000) {
      setTopupErrors("Amount must be between 1000 and 15000");
      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          amount


        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });


      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmitPay(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      } else {

        const data = await response.json();

        if (data.error) {
          setTopupErrors(data.error);
        } else {
          // setStu_Data1(data);
          setAmount("");


          window.payhere.onCompleted = function onCompleted(OrderID) {
            // alert("Payment completed. OrderID:" + amount);
            setOpenAccountBalanceModal(true);
            get_AccBalance();
          };


          window.payhere.onDismissed = function onDismissed() {

            console.log("Payment dismissed");
            setOpenAccountBalanceModal(true);
            get_AccBalance();
          };


          window.payhere.onError = function onError(error) {

            console.log("Error:" + error);
          };

          // console.log('stu_Data1.order_id',stu_Data1.order_id)

          const payment = {
            sandbox: `${process.env.REACT_APP_SANDBOX}`,
            merchant_id: data.merchantId,
            return_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            cancel_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
            notify_url: `${process.env.REACT_APP_NOTIFY_URL}`,
            order_id: data.order_id.toString(),
            items: 'Account Topup',
            currency: data.currency.toString(),
            amount: amount.toString(),
            first_name: data.first_name.toString(),
            last_name: data.last_name.toString(),
            email: data.email.toString(),
            phone: data.phone.toString(),
            address: data.address.toString(),
            custom_1: data.studentID.toString(),
            city: data.city.toString(),
            country: 'Sri Lanka',
            hash: data.hash.toString(),
          };
          window.payhere.startPayment(payment);
          setOpenTopUpModal(false);
          // setOpenTimeSlotModal(true);


        }
      }
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }


    } catch (error) {
      console.error("Error fetching data: ", error);

    }
  };

  const get_AccBalance = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_accBalance`, {
      method: "POST",
      body: JSON.stringify({
        studentID,

      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 403 || response.status === 401) {
      let refreshToken = '';
      if (typeof localStorage !== 'undefined') {
        refreshToken = window.localStorage.getItem('refreshToken');
      }

      try {
        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        });

        if (tokenResponse.status === 200) {
          const tokendata = await tokenResponse.json();
          window.localStorage.setItem('token', tokendata.accessToken);
          token = tokendata.accessToken;


          return handleSubmitPay();
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        handleLogout();
      }

      return;
    } else {

      const data = await response.json();

      if (response.status === 200) {
        setAccBalance((prevState) => ({
          ...prevState,
          acc_balance: data.acc_balance
        }));
      }
    }
  }

  const handlehome = () => {
    navigate('/')
  }

  const handleRecordLink = (link) => {
    setRecordLinks(link)
    setRecordOpen(true)
  }

  const handleDownloadVideo = (link) => {
    setRecordLinks(link)
    setDownloadOpen(true)
  }

  const isOlderThanFiveDays = (date) => {
    const itemDate = new Date(date);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 20); // Subtract 5 days from current date
    return itemDate < currentDate;
  };

  const isUntilTwentyDays = (date) => {
    const itemDate = new Date(date);
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 14); // Subtract 5 days from current date
    return itemDate > currentDate;
  };

  return (
    <div>
      {/* <CardHeader title={<Typography sx={{ fontSize: '15px' }}>Completed Classes</Typography>} sx={{ background: 'linear-gradient(to right , #101847, #00669e)', color: 'white', mb: 1 }} /> */}
      <CardContent>
        {loading1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularIndeterminate />
          </Box>
        ) : (
          <Grid container sx={{ flexGrow: 1 }}>
            {classData.length === 0 ? (

              <>
                <Typography
                  sx={{ mx: 'auto', display: 'flex', alignItems: 'center', color: '#c0c0c0', mb: 2 }}>
                  <ErrorOutlineIcon /> There are no completed classes
                </Typography>

                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#dbe9f2b8", borderRadius: 2, py: 2, boxShadow: "inset 0px 0px 2px #10184724;", cursor: "pointer" }}>
                  <ControlPointIcon sx={{ fontSize: 50, mb: 1, color: "#00669eab" }} onClick={handlehome} />
                  <Typography onClick={handlehome} sx={{ justifyContent: "center", textAlign: 'center', color: "#00669eab" }}>Book A Class</Typography>
                </Grid>
              </>
            ) : (
              classData.map((item, index) => (
                <Grid item xs={12} key={index} >
                  {item.rate > 0 ? (
                    <Card sx={{ mb: 2, mt: 2, boxShadow: 5, borderRadius: 2 }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: { md: 'space-between' }, // Only apply space-between for large screens
                            gap: 1
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Avatar
                              alt="User Picture"
                              src={item.profile}
                              sx={{ width: { xs: 60, sm: 80 }, height: { xs: 60, sm: 80 }, border: 2, borderColor: 'primary.main', cursor: "pointer" }}
                              onClick={() => handleNavigate(item)}
                            />
                            <Box>
                              <Typography variant="h6" sx={{ fontWeight: 600, cursor: "pointer" }} onClick={() => handleNavigate(item)}>
                                {item.t_fname} {item.t_lname}
                              </Typography>
                              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Class ID: {item.ssch_id}
                              </Typography>

                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                              {item.pro_status === 0 && item.timeslts > 0 && item.status == 1 && (
                                <Typography
                                  component="div"
                                  sx={{
                                    color: "white",
                                    backgroundColor: "#0d2353",
                                    borderRadius: '8px',
                                    height: '35px',
                                    width: '140px',
                                    gap: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    '&:hover': {
                                      backgroundColor: "#0d65c8",
                                    },
                                    '&:hover .icon': {
                                      transform: 'translateX(5px)',
                                      transition: 'transform 0.5s ease',
                                    },
                                  }}
                                  onClick={() => handleButtonClick(item.t_id)}
                                >
                                  <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px', fontSize: '14px' }}>
                                    Book Again
                                    <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                                  </Box>
                                </Typography>
                              )}
                            </Box>
                          </Box>

                          <Box sx={{ mt: { xs: 2, md: 0 }, textAlign: { xs: 'left', md: 'left' } }}> {/* Adjust alignment for small screens */}
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              Date: {new Date(item.schedule_date).toLocaleDateString("en-CA")}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              Time: {item.start_time} - {item.end_time}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, flexDirection: { xs: 'row', sm: 'row' } }}>
                          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <Chip avatar={<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <StarIcon sx={{ color: '#faaf00' }} />
                            </Box>} label={item.rate} sx={{ fontWeight: 500 }} />
                          </Box>

                          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <Star value={item.rate} />
                          </Box>


                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            {isUntilTwentyDays(item.schedule_date) && item.record_link.length > 0 && (
                              <Box onClick={() => handleRecordLink(item.record_link)} sx={{ cursor: 'pointer' }}>
                                <PlayCircleIcon color="primary" sx={{ fontSize: 30 }} />
                              </Box>
                            )}
                            {isOlderThanFiveDays(item.schedule_date) && item.dowload_link.length > 0 && (
                              <Box onClick={() => handleDownloadVideo(item.dowload_link)} sx={{ cursor: 'pointer' }}>
                                <DownloadForOfflineIcon color="primary" sx={{ fontSize: 30 }} />
                              </Box>
                            )}
                          </Box>

                        </Box>

                        <Typography variant="body2" sx={{ mt: 2, fontWeight: 500, backgroundColor: '#edecec', p: 0.5, borderRadius: 1 }}>
                          "{item.feedback}"
                        </Typography>

                        <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-end', mt: 1 }}>
                          {item.pro_status === 0 && item.timeslts > 0 && item.status == 1 && (
                            <Typography
                              component="div"
                              sx={{
                                color: "white",
                                backgroundColor: "#0d2353",
                                borderRadius: '8px',
                                height: '35px',
                                width: '140px',
                                gap: '8px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                '&:hover': {
                                  backgroundColor: "#0d65c8",
                                },
                                '&:hover .icon': {
                                  transform: 'translateX(5px)',
                                  transition: 'transform 0.5s ease',
                                },
                              }}
                              onClick={() => handleButtonClick(item.t_id)}
                            >
                              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px', fontSize: '14px' }}>
                                Book Again
                                <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                              </Box>
                            </Typography>
                          )}
                        </Box>
                      </CardContent>


                    </Card>
                  ) : (
                    <Card sx={{ mb: 2, mt: 2, boxShadow: 5, borderRadius: 2 }}>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12}>

                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { md: 'space-between' } // Only apply space-between for large screens
                              }}
                            >
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <Avatar
                                  alt="User Picture"
                                  src={item.profile}
                                  sx={{ width: 80, height: 80, border: 2, borderColor: 'primary.main', cursor: "pointer" }}
                                  onClick={() => handleNavigate(item)}
                                />
                                <Box>
                                  <Typography variant="h6" sx={{ fontWeight: 'bold', cursor: "pointer" }} onClick={() => handleNavigate(item)}>
                                    {item.t_fname} {item.t_lname}
                                  </Typography>
                                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Class ID: {item.ssch_id}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box sx={{ mt: { xs: 2, md: 0 }, textAlign: { xs: 'left', md: 'left' } }}> {/* Adjust alignment for small screens */}
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  Date: {new Date(item.schedule_date).toLocaleDateString("en-CA")}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  Time: {item.start_time} - {item.end_time}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                        </Grid>
                        <Box sx={{ mt: 1 }}>
                          <Star1
                            value={stars[item.ssch_id] || 0}
                            onChange={(newValue) => handlestars(newValue, item.ssch_id)}

                          />
                        </Box>
                        <TextField
                          fullWidth
                          label="Feedback"
                          value={feedbacks[item.ssch_id] || ""}
                          onChange={(event) => handlefeedback(event, item.ssch_id)}
                          sx={{ mt: 1 }}
                        />
                        {errors[item.ssch_id] && (
                          <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                            {errors[item.ssch_id]}
                          </Typography>
                        )}

                        <Button
                          type="submit"
                          variant="contained"
                          size="medium"
                          sx={{ mt: 2, background: "#00669e", color: 'white' }}
                          onClick={(event) => handleSubmitRate(event, item)}
                        >
                          Submit
                        </Button>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              ))
            )}
          </Grid>
        )}
        {totalItems > 3 && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <TablePagination
              component="div"
              count={totalItems}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={itemsPerPage}
              rowsPerPageOptions={[]}
            />
          </Box>
        )}
      </CardContent>

      <Dialog open={openTimeSlotModal} onClose={handleCloseTimeSlotModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', background: 'linear-gradient(to right , #101847, #00669e)', color: 'white' }}>
          <DialogTitle>Schedule a class</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTimeSlotModal} />
          </Tooltip>
        </Grid>

        <DialogContent>
          <Stack direction="row" justifyContent='space-between' >
            <Grid container spacing={2} alignItems="center">
              <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar src={teacher?.profile} alt="Teacher" sx={{ width: 100, height: 100 }} />
              </Grid>
              <Grid item>
                <Typography color='black'>{teacher.t_fname} {teacher.t_lname}</Typography>
              </Grid>
            </Grid>


            <Grid item container direction="column" justifyContent="center" alignItems="flex-end"  >
              <Grid item >
                <Star value={teacher.rate ? teacher.rate : 0} />
              </Grid>

              <Grid item sx={{ mr: 2 }}>
                <Typography>LKR {teacher.t_hourrate?.toFixed(2)}</Typography>
                <Typography mt="6px" style={{ fontSize: 14, fontWeight: 100 }}>Per Hour</Typography>
              </Grid>
            </Grid>

          </Stack>


          {/* <DialogContentText sx={{ mt: 1 }}>Select Date?</DialogContentText> */}
          <FormControl fullWidth>
            <Select sx={{ mt: 2 }} value={date} onChange={handleDateChange} displayEmpty>
              <MenuItem value="" disabled>Please Select A Date</MenuItem>
              {availableDate.map((item, index) => (
                <MenuItem key={index} value={item.schedule_date}>
                  {new Date(item.schedule_date).toLocaleDateString("en-CA")}
                </MenuItem>
              ))}
            </Select>
            {errors && (
              <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                {errors}
              </Typography>
            )}
          </FormControl>

          {date && (
            <>
              <DialogContentText sx={{ mt: 1, mb: 1 }}>Select Time Slots (Sri Lankan Time - GMT+5:30)</DialogContentText>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Grid container spacing={1}>
                  {availableTime.map((item, index) => (
                    <Grid item key={index} >
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#00669e' : 'transparent',
                          color: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? 'white' : 'black',
                          '&:hover': {
                            backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#005bb5' : '#e0e0e0'
                          }
                        }}

                        onClick={() => {
                          handleTimeChange();
                          handleSelectTimeSlot(item);
                        }}
                      >
                        {item.start_time} - {item.end_time}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {errors1 && (
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                    {errors1}
                  </Typography>
                )}
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            sx={{
              type: "submit",
              dispaly: 'flex',
              justifyContent: 'center',
              color: 'white',
              backgroundColor: '#0d2353',
              '&:hover': { backgroundColor: '#0d65c8' },
              variant: 'contained',
              borderRadius: '6px',
              mb: 2.5,
              px: 2,
            }}
            onClick={() => handleopenAccountBalanceModal()}
          >
            Schedule a class
          </Button>
        </DialogActions>
      </Dialog>

      <CustomDialog open={openAccountBalanceModal} onClose={handleCloseAccountBalanceModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Close" >
            <CloseIcon sx={{ mt: 1, mr: 1, cursor: 'pointer', border: '1px solid black', borderRadius: 5, width: '35px', height: '35px' }} onClick={handleCloseAccountBalanceModal} />
          </Tooltip>
        </Grid>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container sx={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ color: '#101847', fontSize: 18 }}>
              Review and Confirm Your Booking Details
              <Divider sx={{ p: 0.5, mb: 3 }} />
            </Typography>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Date:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{schedule_date}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Time:</Typography>
              <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}>(Total Hours:{accBalance.totalHours})</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              {selectedSlots.map((slot, index) => (
                <Typography key={index}>
                  {slot.start_time} - {slot.end_time}
                </Typography>
              ))}
            </Grid>


            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Per Hour Rate:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{currency} {Number(accBalance.t_hourrate)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ my: 2 }}>
              <Typography>Total Fee:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mt: 2 }} >
              <Typography sx={{ borderBottom: 1, borderTop: 1 }}>{currency} {Number(accBalance.totalAmount)?.toFixed(2)}
                <Grid container justifyContent="flex-end">
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={6}  >
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(accBalance.acc_balance)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>New Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(newAccountBalance)?.toFixed(2)}</Typography>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          {accBalance.acc_balance >= accBalance.totalAmount ? (
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, padding: 2 }}>
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  border: '2px solid #0d65c8',
                  borderColor: '#0d65c8',
                  borderRadius: '6px',
                  px: 2,
                }}

                onClick={handleChangeSheduleDate}
              >
                Change Slots
              </Button>

              <Button
                type="submit"
                sx={{
                  color: 'white',
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: 'contained',
                  borderRadius: '6px',
                  px: 2,
                }}
                onClick={handleSubmit}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Confirm Your Class"}
              </Button>

              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: 'pointer', display: { xs: 'block', sm: 'none' }, textAlign: 'center' }}
                onClick={handleChangeSheduleDate}
              >
                Change Slots
              </Typography>

            </Box>
          ) : (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography sx={{ textAlign: 'center', color: 'orange', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  <ErrorOutlineIcon sx={{ color: 'orange', fontSize: '1.3rem' }} />
                  Your account balance is insufficient. Please Top up your account.
                </Typography>
              </Grid>

              <Grid container direction="row" justifyContent="center" gap={2} p={2}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    border: '2px solid #0d65c8',
                    borderColor: '#0d65c8',
                    borderRadius: '6px',
                    px: 2,
                  }}

                  onClick={handleChangeSheduleDate}
                >
                  Change Slots
                </Button>

                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: '#0d2353',
                    '&:hover': { backgroundColor: '#0d65c8' },
                    variant: 'contained',
                    borderRadius: '6px',
                  }}
                  onClick={() => handleOpenTopUpModal(Math.abs(newAccountBalance))}
                >
                  Top-up
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </CustomDialog>

      <Dialog open={openTopUpModal} onClose={handleCloseTopUpModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
          <DialogTitle>Top-Up your Account</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTopUpModal} />
          </Tooltip>
        </Grid>


        <DialogContent sx={{ paddingTop: 1 }}>
          <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
            <CardContent>

              <Box
                sx={{
                  width: '100%',
                  background: '#fafafa',
                  borderRadius: 2,
                  boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)',
                  p: 1.2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 20 },
                    fontWeight: '500',
                    color: 'black',
                  }}
                >
                  Account Balance : {accBalance.acc_balance}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: { xs: 12, sm: 15 },
                      fontWeight: '500',
                      color: '#888885',
                    }}
                  >
                    {currency}
                  </Typography>
                </Typography>
              </Box>

              <Grid container spacing={2} sx={{ mt: 1.5 }} >
                <Grid item xs={12} sm={6} >
                  <TextField type='text' inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }} label="Amount" size="large" value={amount} onChange={handleAmountChange} onKeyDown={handleEnter} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ border: '1px solid black', borderRadius: 1, backgroundColor: '#101847', height: 56 }}
                    onClick={(event) => handleSubmitPay(event, studentID, amount)}
                    fullWidth
                  >
                    Pay with card
                  </Button>
                </Grid>

                {topuperrors ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0, marginLeft: '16px' }}>
                    {topuperrors}
                  </Typography>
                  : null
                }

                <Grid item xs={12} sx={{ mt: 1.5, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">

                    <Box sx={{ display: 'flex' }} >
                      <img src="https://box1.ozonedesk.info/upload/27Nov2024074045_PayHere-Logo.png" alt="PayHere" style={{ maxWidth: '70px', height: 'auto', marginRight: 5 }} />
                      <Icon icon="logos:visa" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="logos:mastercard" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="simple-icons:americanexpress" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="noto:bank" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                    </Box>
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>

      <RecordLink setRecordOpen={setRecordOpen} recordOpen={recordOpen} recordLinks={recordLinks} />
      <DownloadVideo downloadOpen={downloadOpen} setDownloadOpen={setDownloadOpen} recordLinks={recordLinks} />
    </div>
  );
}
export default CompletedClassForm;