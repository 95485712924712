import React, { useEffect, useState } from 'react'
import { Card, CardContent, CardActions, Typography, Button, Grid, Chip, Box, CircularProgress, TextField } from "@mui/material";
import { styled } from "@mui/system";
import Swal from 'sweetalert2'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react/dist/iconify.js';
import Star1 from '../components/completedcardstar';
import { Rating } from '@mui/material';


const RateClass = ({ }) => {
  const [classDetails, setClassDetails] = useState({
    class_end: 0,
    end_time: "",
    schedule_date: "",
    ssch_id: 0,
    start_time: "",
    stu_fname: "",
    stu_lname: "",
    stu_id: 0,
    t_id: 0,
    totalAmount: 0,
    tsch_id: '',
    rate: 0
  })
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(false)
  const [feedbacks, setFeedbacks] = useState("");
  const [stars, setStars] = useState(0);
  const [errors, setErrors] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false)


  const location = useLocation();

  // Extract the email parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const id = queryParams.get('id');


  console.log(id, token)

  useEffect(() => {
    fetchClass()
    fetchSystemSetting()
  }, [id])

  const fetchClass = async () => {
    setLoading(true)

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/rating_class`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        id: id,
        token: token
      })
    })
    const data = await response.json()
    if (response.status == 200) {
      setLoading(false)
      setClassDetails(data.result)
    } else {
      Swal.fire({
        title: `${data.message}`,
        icon: 'error',
        confirmButtonColor: '#00669e',
        customClass: {
          container: 'custom-swal-container'
        }
      })
    }
  }

  const fetchSystemSetting = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      })
      if (response.status == 200) {
        const data = await response.json()
        setLogo(data.result.logo)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }


  const formatDate = dateString => {

    return new Date(dateString).toLocaleDateString('en-CA')
  }

  const handleSubmitRate = async (event, t_id) => {
    event.preventDefault();

    if (stars === 0) {
      setErrors("Please rate");
      return;
    }

    setSubmitLoading(true)

     Swal.fire({
          title: `Rate Confirmation`,
          text: `Are you sure you want to submit this rating?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#00669e',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancel',
          confirmButtonText: 'Confirm',
          reverseButtons: true,
          customClass: {
            container: 'custom-swal-container' // Add a custom class to the container
          }
        }).then(async result => {
          if (result.isConfirmed) {
            try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/DirectClassFeedback`, {
                method: 'POST',
                body: JSON.stringify({
                  stars,
                  t_id : t_id,
                  feedback: feedbacks,
                  ssch_id: id,
                  studentID: classDetails.stu_id
                }),
                headers: {
                  'Content-Type': 'application/json',
                }
              });
        
              if (!response.ok) {
                throw new Error(`HTTP error: Status ${response.status}`);
              }
        
              const data = await response.json();
        
              if (response.status === 200) {
                setStars(0);
                setFeedbacks("");
                fetchClass();
              } else {
                setErrors("rate failed");
              }
        
            } catch (error) {
              console.error("Error fetching data: ", error);
              setErrors( "Error submitting rating and feedback" , error);
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire({
              title: 'Cancelled',
              icon: 'error',
              confirmButtonColor: '#00669e',
              customClass: {
                container: 'custom-swal-container' // Add a custom class to the container
              }
            })
          }
        })

   
  };

  return (
    <div>


      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', px: 2 }}>
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <Card variant="outlined" sx={{ boxShadow: 2, }}>
              <CardContent sx={{ textAlign: 'center', minWidth: 300 }}>
                <Box sx={{ textAlign: 'center', mb: 6, }}>
                  <img
                    src={logo ? logo : ''}
                    alt='English teacher'
                    style={{ width: '200px', height: 'auto' }}
                  />

                </Box>
                <Typography variant="h6" color="primary" gutterBottom>
                  Class ID: {classDetails?.ssch_id}
                </Typography>
                <Typography variant="body1" sx={{ mt: 1 }}>
                  <strong>Student:</strong> {`${classDetails?.stu_fname} ${classDetails?.stu_lname}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  <strong>Date:</strong> {formatDate(classDetails?.schedule_date)}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  <strong>Time:</strong> {`${classDetails?.start_time} - ${classDetails?.end_time}`}
                </Typography>
                {classDetails?.rate > 0 ? (

                  <Box sx={{ mt: 1 }}>
                    <Rating
                      value={classDetails?.rate / 20}
                      readOnly
                    />
                  </Box>

                ) : (
                  <>
                    <Box sx={{ mt: 1 }}>
                      <Rating
                        value={stars}
                        onChange={(event, newValue) => {
                          setStars(newValue)
                        }}

                      />

                      <TextField
                        fullWidth
                        label="Feedback"
                        value={feedbacks}
                        onChange={(event) => setFeedbacks(event.target.value)}
                        sx={{ mt: 1 }}
                        error={!!errors}
                        helperText={errors}
                      />
                    </Box>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ mt: 2 }}
                      onClick={(event) => handleSubmitRate(event, classDetails?.t_id)}
                    >
                      Submit
                    </Button>
                  </>
                )}

              </CardContent>
            </Card>
          )}
        </Box>
      </Box>

    </div>
  )
}



export default RateClass