import React from 'react';
import Navbar from '../components/navbar';
import NavbarLog from '../components/navbarlogged';
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Container, Card, CardContent } from '@mui/material';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  FormControl, MenuItem, Box, Select, TextField, Tooltip, Stack, Avatar, IconButton, InputAdornment, Alert, AlertTitle
} from '@mui/material';
import { Button } from "@mui/material";
import Footer from '../components/footer';
import Star from '../components/star';
import Divider from '@mui/material/Divider';
import { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';
import TablePagination from '@mui/material/TablePagination';
import CircularIndeterminate from '../components/loading';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { NativeSelect } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import useLogout from '../hooks/logouthook';
import { Helmet } from "react-helmet";
import themeConfig from '../config/themeConfig';
import { Icon } from '@iconify/react/dist/iconify.js';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CardHeader from '@mui/material';
import { CircularProgress } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from '@mui/material/styles';
import useSubscribe from '../hooks/subscribehook';
import BlockIcon from "@mui/icons-material/Block";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function CustomPopper(props) {
  return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));

function TeacherProfile() {
  const studentsID = localStorage.getItem('studentID');
  const [teacherData, setTeacherData] = useState({});
  const [studentData, setStudentData] = useState([]);
  // const { t_id: urlT_id, t_fname: urlT_fname } = useParams();
  // const [t_id, setT_id] = useState(urlT_id || "");
  const [teacherID, setTeacherID] = useState("");
  const [errors, setErrors] = useState('');
  const [errors1, setErrors1] = useState('');
  const [errors2, setErrors2] = useState("");
  const [topuperrors, setTopupErrors] = useState('');
  const [teacher, setTeacher] = useState([]);
  const [date, setDate] = useState("");
  const [openMobileNumberModal, setOpenMobileNumberModal] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [openOTPModal, setOpenOTPModal] = React.useState(false);
  const [openTimeSlotModal, setOpenTimeSlotModal] = useState(false);
  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [amount, setAmount] = useState('');
  const [openAccountBalanceModal, setOpenAccountBalanceModal] = useState(false);
  const [accBalance, setAccBalance] = useState({ totalAmount: 0, acc_balance: 0 });
  const [schedule_date, setSchedule_date] = useState("");
  const [availableDate, setAvailableDate] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [maxHour, setMaxHour] = useState(0);
  const [studentID, setStudentID] = useState("");
  const inputRefs = useRef([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const [loading1, setLoading1] = useState(true);
  const navigate = useNavigate('');
  const [stu_fname, setStu_fname] = useState("");
  const [stu_lname, setStu_lname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [currency, setCurrency] = useState("");
  const [NIC, setNIC] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [enterPassword, setEnterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [renewPassword, setReNewPassword] = useState('');
  const [shownewPassword, setShowNewPassword] = useState('');
  const [showrenewPassword, setShowReNewPassword] = useState('');
  const [newpasswordError, setNewPasswordError] = useState('');
  const [emailpassword, setEmailpassword] = useState('');
  const [showemailPassword, setShowemailPassword] = useState(false);
  const [emailPassworderror, setEmailPassworderror] = useState('');
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [openUserDetailsEmail, setOpenUserDetailsEmail] = useState(false);
  const { handleLogout } = useLogout();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [teacherloading, setTeacherloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [hotline, setHotline] = useState('')


  const theme = useTheme()
  const lgAbove = useMediaQuery(theme.breakpoints.up("lg"));
  const { handlepushNotification } = useSubscribe()

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Manually parse the URL
  const pathname = location?.pathname; // Get the current URL
  const parts = pathname?.split('_'); // Split by the underscore

  // Check if parts[2] exists before trying to split it
  const splitid = parts?.[2];
  let t_id;

  if (splitid) {
    const idnameparts = splitid.split('-');

    // Check if idnameparts[0] exists before assigning it to t_id
    t_id = idnameparts?.[0];
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleButtonClick = async (id) => {
    // setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (studentData.temp == 0 && !studentData.email) {
          handleOpenProfileModal(true);
        } else if (studentData.temp == 0 && !studentData.phone_num) {
          setOpenUserDetailsEmail(true);
        } else {
          handleOpenTimeSlotModal(id);
        }
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
      handleOpenMobileNumberModal();
    }
  };

  function SkillsArrayDefine({ skills }) {
    // Ensure skills is defined, default to an empty array if not
    const skillList = skills || [];

    return (
      <>
        {skillList.length > 0 && (
          <ul>
            {skillList.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        )}
      </>
    );
  }

  useEffect(() => {
    fetchTeacherData();
    fetchSocialLinks()
  }, [t_id]);

  const fetchTeacherData = async () => {
    setTeacherloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacher_profile/${t_id}`, {
        method: 'POST',
        body: JSON.stringify({
          t_id
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setTeacherData(getData.Data);
      setTeacherloading(false);
    } catch (err) {
      console.error('Error fetching teacher data:', err);
    }
  };

  const fetchStudentData = async (pageNum) => {
    try {
      setLoading1(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/last_reviews`, {
        method: 'POST',
        body: JSON.stringify({
          t_id,
          page: pageNum,
          itemsPerPage,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setStudentData(getData.data);
      setTotalItems(getData.totalItems);
    } catch (err) {
      console.error('Failed to fetch data', err);
      setStudentData([]);
    } finally {
      setLoading1(false);
    }
  };

  useEffect(() => {
    fetchStudentData(page);
  }, [t_id, page]);


  const fetchTeacher = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/teacherData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          t_id
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setTeacher(getData.Data[0]);
    } catch (err) {
      console.error('Error fetching teacher data:', err);
    }
  };


  // mobileNumberModal

  const validatePhoneNumber = (mobileNumber) => {
    return /^(0|\+94|94)?[0-9]{9}$/.test(mobileNumber);
  };

  const handleOpenMobileNumberModal = () => {
    setOpenMobileNumberModal(true);
  };

  const handleCloseMobileNumberModal = () => {
    setOpenMobileNumberModal(false);
    setErrors("");
    setMobileNumber("");
  };

  const handleMobileNumberChange = (event) => {
    let value = event.target.value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/;
    setErrors(""); // Clear any previous errors

    if (!value) {
      setErrors("");
    }
    else if (!/^\+?[\d]+$/.test(value) && !emailRegex.test(value)) {
      // Check if it's neither a valid mobile number nor a valid email
      setErrors("Invalid mobile number or email.");
    }
    else if (/^\+?[\d]+$/.test(value)) {
      // If it's a mobile number, perform additional checks
      if (value.startsWith("7")) {
        if (value.length !== 9) {
          setErrors("Invalid mobile number. Must be 9 digits when starting with '7'.");
        }
      } else if (value.startsWith("07")) {
        if (value.length !== 10) {
          setErrors("Invalid mobile number. Must be 10 digits when starting with '07'.");
        }
      } else if (value.startsWith("947")) {
        if (value.length !== 11) {
          setErrors("Invalid mobile number. Must be 11 digits when starting with '947'.");
        }
      } else if (value.startsWith("+947")) {
        if (value.length !== 12) {
          setErrors("Invalid mobile number. Must be 12 digits when starting with '+947'.");
        }
      } else {
        setErrors("Invalid mobile number format.");
      }
    }

    setMobileNumber(value); // or setEmail if handling email input
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleCheck(event);
    }
  };

  const handleSubmitLogin = async (event) => {
    event.preventDefault();

    if (!mobileNumber) {
      setErrors("Please enter a Mobile number");
    } else if (!validatePhoneNumber(mobileNumber)) {
      setErrors("Invalid mobile number. Please enter a valid mobile number.");
    } else if (!(mobileNumber.length <= 14)) {
      setErrors("Invalid length");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/student_login`,
          {
            method: "POST",
            body: JSON.stringify({
              mobileNumber,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );


        if (response.status === 403) {
          handleCloseMobileNumberModal(true);
          const data = await response.json();
          Swal.fire({
            icon: 'error',
            title: data.title,
            text: data.message,
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK',
            customClass: {
              container: 'custom-swal-container'
            }
          });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }

        const data = await response.json();

        if (
          data.message === "OTP updated successfully" ||
          data.message === "Mobile number and OTP inserted successfully"
        ) {
          setOpenMobileNumberModal(false);
          handleOpenOTPModal();
          setStudentID(data.stu_id);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  // const handleMobileNumberChange = (event) => {
  //   setMobileNumber(event.target.value); // Update mobileNumber state
  // };

  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  const mobileRegex = /^\+?[\d]+$/;

  const handleCheck = async (event) => {
    event.preventDefault();
    setLoading(true);

    const inputValue = mobileNumber;

    if (emailRegex.test(inputValue)) {
      setErrors("");
      await handleSubmitemail(inputValue);

    } else if (mobileRegex.test(inputValue)) {
      setErrors("");
      await handleSubmitMobile(event);

    } else {
      setErrors("Please enter a valid mobile number or email.");
    }
    setLoading(false);
  };




  //Password Model

  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);

  };

  const handleSubmitemail = async (event) => {
    console.log("object")
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (!mobileNumber) {
      setEmailError("Please enter an email");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/student_loginwithemail`,
        {
          method: "POST",
          body: JSON.stringify({ mobileNumber }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 403) {
        const data = await response.json();
        Swal.fire({
          icon: 'error',
          title: data.title,
          text: data.message,
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
        return;
      }

      const data = await response.json();

      if (response.status === 404) {
        setEnterPassword('emailpassowrd')
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();

      }

      if (response.status === 400) {
        setEnterPassword("set")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
      }

      if (response.status === 200) {
        setEnterPassword("enter")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
        setEmailError("");
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setEmailError("An error occurred while trying to log in. Please try again later.");
    }
  };


  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
    setErrors("");
    setErrors2("");
    setPassword("");
    setNewPassword("");
    setEmailpassword("");
    setMobileNumber("");
  };

  const handlepassword = (event) => {
    setPassword(event.target.value);
    setErrors("")
  };

  const handleEnterPassword = (event) => {
    if (event.key === "Enter") {
      handleNext(event);
    }
  };

  const handleNext = async () => {
    setErrors1("");
    setLoading(true);
    try {
      if (!password) {
        setErrors2("Please enter the password");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_verify_password`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          mobileNumber,
          password
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }


      const data = await response.json();

      if (response.status === 200) {
        handleClosePasswordModal();
        setPassword("");

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${data.accessToken}`
          },
        });


        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (studentData.temp == 0) {
          setOpenUserDetailsEmail(true);
        } else {
          handleOpenTimeSlotModal(t_id)
        }

      } else if (response.status === 404) {
        setLoading(false);
        setErrors1(data.message);
      } else {
        setLoading(false);
        setErrors1(data.message);
      }
    } catch (error) {
      setErrors("An error occurred ");
    }
    setLoading(false);
  };

  const handleEnterSetPassword = (event) => {
    if (event.key === "Enter") {
      handleNewPasswordNextSubmit(event);
    }
  };

  const handleNewPasswordNextSubmit = async (event) => {
    event.preventDefault();

    setErrors2("");

    if (!newPassword) {
      setErrors2("Please enter a password");
      return
    }
    if (newPassword.length < 6) {
      setErrors2("The password must be equal or more than 6 characters.");
      return
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            studentID,
            mobileNumber,
            newPassword
          }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });



      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();

      if (response.status === 200) {
        setOpenPasswordModal(false);
        setNewPassword("");

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

      }
      const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
        method: "POST",
        body: JSON.stringify({ studentID: data.studentID }),
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`
        },
      });


      if (!studentResponse.ok) {
        throw new Error(`HTTP error: Status ${studentResponse.status}`);
      }

      const studentData = await studentResponse.json();

      if (studentData.temp == 0) {
        setOpenUserDetailsEmail(true);
      } else {
        handleOpenTimeSlotModal(t_id)
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors2(error.message);
    }
    setLoading(false);
  };

  const handleEnterSetPasswordBoth = (event) => {
    if (event.key === "Enter") {
      handleEnterEmailPassword(event);
    }
  };

  const handleEnterEmailPassword = async () => {
    setErrors2("");

    if (!emailpassword) {
      setErrors2("Please enter a password");
      return
    }

    if (emailpassword.length < 6) {
      setErrors2("The password must be equal or more than 6 characters.");
      return
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewEmailPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            mobileNumber,
            emailpassword
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

        setOpenPasswordModal(false);
        setOpenUserDetailsEmail(true);
        setEmailpassword("");

      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors2(error.message);
    }
    setLoading(false);
  }

  const handleChangeEmailPassword = () => {
    setOpenPasswordModal();
    setOpenMobileNumberModal(true);
  };

  //Forgot Password Modal

  const handleChangeForgotPassword = () => {

    setOpenPasswordModal();
    navigate(`/forgot_password?semail=${JSON.stringify(mobileNumber)}`)
  };

  // otpModal

  // useEffect(() => {
  //   if (openOTPModal) {
  //     setCountdown(60);
  //   }


  //   const timer = countdown > 0 && setInterval(() => {
  //     setCountdown((prevCountdown) => prevCountdown - 1);
  //   }, 1000);


  //   return () => clearInterval(timer);
  // }, [openOTPModal]);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);

      // Cleanup interval on component unmount or when timeLeft changes
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const handleSubmitMobile = async (event) => {
    event.preventDefault();

    if (!mobileNumber) {
      setErrors("Please enter a Mobile number");
    } else if (!validatePhoneNumber(mobileNumber)) {
      setErrors("Invalid mobile number. Please enter a valid mobile number.");
    } else if (!(mobileNumber.length <= 14)) {
      setErrors("Invalid length");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/student_login`,
          {
            method: "POST",
            body: JSON.stringify({
              mobileNumber,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 403) {
          handleCloseMobileNumberModal(true);
          const data = await response.json();
          Swal.fire({
            icon: 'error',
            title: data.title,
            text: data.message,
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK',
            customClass: {
              container: 'custom-swal-container'
            }
          });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }

        const data = await response.json();

        if (
          data.message === "OTP updated successfully" ||
          data.message === "Mobile number and OTP inserted successfully"
        ) {
          setOpenMobileNumberModal(false);
          handleOpenOTPModal();
          setStudentID(data.stu_id);
          setErrors("");
        } else if (data.message == "Invalid") {
          setErrors("Invalid Phone Number");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleValue(event);
    }
  };

  const handleOpenOTPModal = () => {
    setCountdown(60)
    setOpenOTPModal(true);
    setTimeout(() => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, 0);
  };

  const handleCloseOTPModal = () => {
    setOpenOTPModal(false);
    setErrors("");
    setMobileNumber("");
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const setCaretToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  };

  // const handleInput = (index, event) => {
  //   const value = event.target.innerText.replace(/[^0-9]/g, "");
  //   const newOTP = [...otp];
  //   newOTP[index] = value.slice(-1);
  //   setOTP(newOTP);

  //   // Clear any additional input and set the correct value
  //   event.target.innerText = value.slice(-1);

  //   // Move focus to next input field if available
  //   if (value && index < otp.length - 1) {
  //     inputRefs.current[index + 1].focus();
  //   } else {
  //     setCaretToEnd(event.target);
  //   }
  // };

  // const handleKeyDown = (index, event) => {
  //   if (
  //     event.key === "Backspace" &&
  //     !otp[index] &&
  //     index > 0 &&
  //     inputRefs.current[index - 1]
  //   ) {
  //     inputRefs.current[index - 1].focus();
  //     setCaretToEnd(inputRefs.current[index - 1]);
  //   }
  // };

  const handleInput = (event, index) => {
    const value = event.target.value;

    // Check if the value is a valid number
    if (/^\d+$/.test(value)) {
      const newOtp = [...otp];

      // Extract only the last digit of the input
      newOtp[index] = value.slice(-1);

      // Update the OTP state with the new value
      setOTP(newOtp);

      // Focus the next text field if available
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        setCaretToEnd(event.target);
      }
    }
  };



  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" || event.key === "Cancel") {
      const newOtp = [...otp];
      if (otp[index]) {
        // Clear current field
        newOtp[index] = '';
        setOTP(newOtp);
      } else if (index > 0) {
        // Move to the previous field if empty
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleClick = (index) => {
    const newOtp = [...otp];  // Create a copy of the OTP array
    newOtp[index] = "";       // Clear the value of the clicked field
    setOTP(newOtp);
    inputRefs.current[index].focus();
    setFocusedIndex(index)

  };

  const handleValue = async () => {
    setErrors("");
    setLoading(true);
    try {
      if (!otp) {
        setErrors("Please enter OTP.");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          otp: otp.join(""),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();

      if (response.status === 200) {
        setOTP(new Array(4).fill(""));
        setOpenOTPModal(false);

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)
        handlepushNotification(data.accessToken)
        handleCloseOTPModal();

        // check neew user or not
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (studentData && (!studentData.stu_fname || !studentData.stu_lname || !studentData.email || !studentData.address || !studentData.city || !studentData.country)) {
          handleOpenProfileModal();
        } else {
          handleOpenTimeSlotModal(t_id);
        }

      } else {
        setErrors(data.error);
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors("An error occurred while verifying OTP.");
    }
    setLoading(false);
  };

  const handleChange = () => {
    setOpenOTPModal(false);
    setOpenMobileNumberModal(true);
  };

  const handleSend = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobileNumber }),
      });

      const data = await response.json();

      if (response.ok) {

        setOpenOTPModal(true);

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'Failed to generate OTP. Please try again.',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
      }
    } catch (error) {

    }
  };

  const handleLoginSignup = async () => {
    console.log("Login successful!");
    handleCloseMobileNumberModal();
    setOpenOTPModal(true);
  };

  const handleOTPVerify = async () => {
    console.log("Login successful!");

    window.location.href = "/dashboard";

  };

  // Time Slot Modal

  const handleOpenTimeSlotModal = (id) => {
    setOpenTimeSlotModal(true);
    fetchAvailableDate(id);
    fetchTeacher(id);
    setErrors("");
    setErrors1("");
  };

  const handleCloseTimeSlotModal = () => {
    setOpenTimeSlotModal(false);
    setDate("");
    setSelectedSlots([]);
    setErrors("");
    setErrors1("");
  };


  // AccountBalance Modal

  const newAccountBalance = accBalance.acc_balance - accBalance.totalAmount;


  const handleopenAccountBalanceModal = async () => {

    if (!date) {
      setErrors("Please select a date");
    } else if (selectedSlots.length === 0) {
      setErrors1('Please select time slots that you want.');

    } else {
      try {
        await fetchAccBalance();
        setOpenAccountBalanceModal(true);
        setOpenTimeSlotModal(false);
        setDate("");

      } catch (error) {
        console.error(error);
      }

    }
  };

  const handleCloseAccountBalanceModal = () => {
    setOpenAccountBalanceModal(false);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setDate(selectedDate);
    fetchAvailableTime(selectedDate);
    setSelectedSlots([]);
    setErrors("");
  };

  const handleTimeChange = () => {
    setErrors1("");
  };

  const handleChangeSheduleDate = () => {
    setOpenAccountBalanceModal(false);
    setOpenTimeSlotModal(true);
  };

  useEffect(() => {
    if (t_id) {
      fetchAvailableDate(t_id);
    }
  }, [t_id]);

  const fetchAvailableDate = async (t_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/book_date/${t_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setAvailableDate(getData.Data);
      setTeacherID(getData.t_id);
    } catch (err) {
      console.error('Error fetching available dates:', err);
    }
  };

  const fetchAvailableTime = async (schedule_date) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/timeslots`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          t_id: teacherID,
          schedule_date
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();
      setAvailableTime(getData.Data);
      setMaxHour(getData.maxHour);
      setSchedule_date(schedule_date);
    } catch (err) {
      console.error('Error fetching available time:', err);
    }
  };

  const handleSelectTimeSlot = (timeSlot) => {
    setErrors("");
    const slotIndex = selectedSlots.findIndex(slot => slot.start_time === timeSlot.start_time && slot.end_time === timeSlot.end_time);
    let newSelectedSlots = [...selectedSlots];

    if (slotIndex > -1) {
      newSelectedSlots.splice(slotIndex, 1); // Deselect if already selected
    } else {
      newSelectedSlots.push(timeSlot); // Select if not already selected
    }

    // Check if the selected slots are within the allowed range
    newSelectedSlots.sort((a, b) => new Date(`1970-01-01T${a.start_time}`) - new Date(`1970-01-01T${b.start_time}`));

    let validSelection = true;

    for (let i = 0; i < newSelectedSlots.length; i++) {
      let consecutiveHours = 0;
      for (let j = i; j < newSelectedSlots.length; j++) {
        const slotStart = new Date(`1970-01-01T${newSelectedSlots[j].start_time}`);
        const slotEnd = new Date(`1970-01-01T${newSelectedSlots[j].end_time}`);

        if (j > i) {
          const previousSlotEnd = new Date(`1970-01-01T${newSelectedSlots[j - 1].end_time}`);
          const gapHours = (slotStart - previousSlotEnd) / (1000 * 60 * 60);

          if (gapHours !== 0) {
            break;
          }
        }

        consecutiveHours += (slotEnd - slotStart) / (1000 * 60 * 60);
        if (consecutiveHours > maxHour) {
          validSelection = false;
          break;
        }
      }
      if (!validSelection) {
        break;
      }
    }

    if (validSelection) {
      setSelectedSlots(newSelectedSlots);
    } else {
      setErrors("You cannot select more than 2 consecutive hours.");
    }

  };

  const fetchAccBalance = async () => {
    try {
      const storedStudentID = localStorage.getItem('studentID');

      if (!storedStudentID) {
        throw new Error("Student ID is not found in session storage");
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/schedule_class`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`

        },
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          selectedSlots
        })
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          console.log("object")
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;
            return fetchAccBalance();
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const getData = await response.json();

      setAccBalance(getData);
      setCurrency(getData.currency);

    } catch (err) {
      console.error("Error fetching account balance: ", err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const storedStudentID = localStorage.getItem('studentID');

    const booked_date = new Date();

    try {

      const balanceResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stu-accountbalance`, {
        method: 'POST',
        body: JSON.stringify({

        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (balanceResponse.status === 403 || balanceResponse.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {

          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;
            return handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }
        setLoading(false);
        return;
      }

      if (balanceResponse.status !== 200) {
        throw new Error(`HTTP error: Status ${balanceResponse.status}`);
      }

      const balanceData = await balanceResponse.json();

      console.log("balance", balanceData.balance)

      setAccBalance((prev) => ({
        ...prev,
        acc_balance: balanceData.balance
      }));

      if (balanceData.balance < accBalance.totalAmount) {
        setOpenAccountBalanceModal(false);

        Swal.fire({
          icon: 'error',
          title: 'Insufficient Balance',
          text: 'Your account balance is insufficient to complete this booking. Please Top-Up your account.',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'Cancel',
          showCancelButton: true,
          cancelButtonText: 'Top-Up',
          cancelButtonColor: '#00669e',
          customClass: {
            container: 'custom-swal-container'
          }

        }).then((result) => {
          if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/account_topup';
          }
        });
        setLoading(false);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/confirm_booking`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          t_id: teacher.t_id,
          teacher: teacher,
          selectedSlots,
          totalAmount: accBalance.totalAmount,
          acc_balance: accBalance.acc_balance,
          booked_date: booked_date.toISOString(),
          newAccountBalance,
          schedule_date: schedule_date
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log("booking")
      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          console.log("object")
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;
            return handleSubmit(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }
        setLoading(false);
        return;
      }


      if (!response.ok) {
        if (response.status === 400) {
          setOpenAccountBalanceModal(false);
          const data = await response.json();

          // Display the SweetAlert based on the error returned
          Swal.fire({
            icon: 'error',
            title: 'Booking Failed',
            text: data.error || 'An error occurred during the booking process.',
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK',
            customClass: {
              container: 'custom-swal-container'
            }
          });

          return;
        }

        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();

      if (data.message === 'Class booked successfully!') {
        setOpenAccountBalanceModal(false);
        setErrors('');

        const scheduleDate = schedule_date;
        // const startTime = selectedSlots[0].start_time; 
        // const endTime = selectedSlots[selectedSlots.length - 1].end_time;


        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          {selectedSlots.map((slot, index) => (
            <Typography key={index}>
              {slot.start_time} - {slot.end_time}
            </Typography>
          ))}
        </Grid>;


        const slotsHtml = selectedSlots.map((slot) => {
          return `<p><strong>Schedule Time:</strong> ${slot.start_time} - ${slot.end_time}</p>`;
        }).join('');

        Swal.fire({
          icon: 'success',
          title: 'Class booked successfully!',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'Class Details',
          showCancelButton: true,
          cancelButtonText: 'Go to dashboard',
          cancelButtonColor: '#101847',
          customClass: {
            container: 'custom-swal-container'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/pendingApproval_class';
          } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
            window.location.href = '/dashboard';
          }
        });
        setLoading(false);
      }
    } catch (error) {
      setOpenAccountBalanceModal(false);
      Swal.fire({
        icon: 'error',
        title: 'Booking Failed',
        text: 'An unexpected error occurred. Please try again later.',
        confirmButtonColor: '#00669e',
        confirmButtonText: 'OK',
        customClass: {
          container: 'custom-swal-container'
        }
      });
      setLoading(false);
    }
  };


  // Top Up Modal

  const handleOpenTopUpModal = (newAccountBalance) => {
    setAmount(newAccountBalance);
    setOpenAccountBalanceModal(false);
    setOpenTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setOpenTopUpModal(false);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setErrors("")


    if (isNaN(value)) {
      setErrors("Please enter a valid amount");
    } else {
      setErrors('');
    }
    setAmount(value);
  };

  const handleEnterPay = (event) => {
    if (event.key === "Enter") {
      handleSubmitPay(event);
    }
  };

  const handleSubmitPay = async (event) => {
    event.preventDefault();

    const storedStudentID = localStorage.getItem('studentID');

    setTopupErrors("");

    if (!amount) {
      setTopupErrors("Please add an amount");
      return;
    }
    const numericAmount = parseInt(amount, 10);
    if (numericAmount < 1000 || numericAmount > 15000) {
      setTopupErrors("Amount must be between 1000 and 15000");
      return;
    }


    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/account_topup`, {
        method: 'POST',
        body: JSON.stringify({
          studentID: storedStudentID,
          amount


        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.status === 403 || response.status === 401) {
        let refreshToken = '';
        if (typeof localStorage !== 'undefined') {
          refreshToken = window.localStorage.getItem('refreshToken');
        }

        try {
          const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refreshToken: refreshToken,
            }),
          });

          if (tokenResponse.status === 200) {
            const tokendata = await tokenResponse.json();
            window.localStorage.setItem('token', tokendata.accessToken);
            token = tokendata.accessToken;


            return handleSubmitPay(event);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error refreshing token:', error);
          handleLogout();
        }

        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();

      if (data.error) {
        setTopupErrors(data.error);
      } else {
        // setStu_Data1(data);
        setAmount("");


        window.payhere.onCompleted = function onCompleted(OrderID) {
          // alert("Payment completed. OrderID:" + amount);
          setOpenAccountBalanceModal(true);
          get_AccBalance();
        };


        window.payhere.onDismissed = function onDismissed() {

          console.log("Payment dismissed");
          setOpenAccountBalanceModal(true);
          get_AccBalance();
        };


        window.payhere.onError = function onError(error) {

          console.log("Error:" + error);
        };

        // console.log('stu_Data1.order_id',stu_Data1.order_id)

        const payment = {
          sandbox: `${process.env.REACT_APP_SANDBOX}`,
          merchant_id: data.merchantId,
          return_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
          cancel_url: `${process.env.REACT_APP_PRODUCTION_URL}/account_topup`,
          notify_url: `${process.env.REACT_APP_NOTIFY_URL}`,
          order_id: data.order_id.toString(),
          items: 'Account Topup',
          currency: data.currency.toString(),
          amount: amount.toString(),
          first_name: data.first_name.toString(),
          last_name: data.last_name.toString(),
          email: data.email.toString(),
          phone: data.phone.toString(),
          address: data.address.toString(),
          custom_1: data.studentID.toString(),
          city: data.city.toString(),
          country: 'Sri Lanka',
          hash: data.hash.toString(),
        };
        window.payhere.startPayment(payment);
        setOpenTopUpModal(false);
        // setOpenTimeSlotModal(true);


      }


    } catch (error) {
      console.error("Error fetching data: ", error);

    }
  };


  //Profile Model

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const handlefirstname = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastname = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handleemail = (event) => {
    setEmail(event.target.value);
    setErrors("")
  };

  const handleaddress = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecity = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountry = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false);
    setErrors("");
  };

  const handleCloseUserDetailsEmail = () => {
    setOpenUserDetailsEmail(false);
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();


    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!email) {
      newErrors.email = "Please enter the Email";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');
      setLoading2(true);
      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
          method: 'POST',
          body: JSON.stringify({
            studentID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            email,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

          }
        });

        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfile(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setLoading2(false);
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setEmail("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModal();
          handleOpenTimeSlotModal(t_id);
        } else {
          setLoading2(false);
          setErrors({ ...errors, email: "This email is already registered. Please use another email." });
        }

      } catch (error) {
        setLoading2(false);
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  const get_AccBalance = async () => {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get_accBalance`, {
      method: "POST",
      body: JSON.stringify({
        studentID,

      }),
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
    });

    if (response.status === 403 || response.status === 401) {
      let refreshToken = '';
      if (typeof localStorage !== 'undefined') {
        refreshToken = window.localStorage.getItem('refreshToken');
      }

      try {
        const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            refreshToken: refreshToken,
          }),
        });

        if (tokenResponse.status === 200) {
          const tokendata = await tokenResponse.json();
          window.localStorage.setItem('token', tokendata.accessToken);
          token = tokendata.accessToken;


          return get_AccBalance();
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Error refreshing token:', error);
        handleLogout();
      }

      return;
    } else {
      const data = await response.json();

      if (response.status === 200) {
        setAccBalance((prevState) => ({
          ...prevState,
          acc_balance: data.acc_balance
        }));
      }

    }
  }

  //ProfileEmail Model


  const handlefirstnameEmail = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastnameEmail = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handlePhone = (event) => {
    let valuemob = event.target.value;
    setErrors({ phone: "" }); // Initialize the errors state
    setMobileError("");

    if (!valuemob) {
      setErrors({ phone: "" }); // Clear errors if input is empty
    } else if (!/^\+?[\d]+$/.test(valuemob)) {
      setErrors({ phone: "Invalid mobile number. Only digits and an optional '+' are allowed." });
    } else {
      if (valuemob.startsWith("7")) {
        if (valuemob.length !== 9) {
          setErrors({ phone: "Invalid mobile number. Must be 9 digits when starting with '7'." });
        }
      } else if (valuemob.startsWith("07")) {
        if (valuemob.length !== 10) {
          setErrors({ phone: "Invalid mobile number. Must be 10 digits when starting with '07'." });
        }
      } else if (valuemob.startsWith("947")) {
        if (valuemob.length !== 11) {
          setErrors({ phone: "Invalid mobile number. Must be 11 digits when starting with '947'." });
        }
      } else if (valuemob.startsWith("+947")) {
        if (valuemob.length !== 12) {
          setErrors({ phone: "Invalid mobile number. Must be 12 digits when starting with '+947'." });
        }
      } else {
        setErrors({ phone: "Invalid mobile number format." });
      }
    }

    setPhone(valuemob);
  };


  const handleaddressEmail = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecityEmail = (event) => {
    setCity(event.target.value);
    setErrors("")
  };


  const handlecountryEmail = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };


  const handleCloseProfileModalEmail = () => {
    setOpenUserDetailsEmail(false);
    setErrors("");
  };

  const capitalizeFirstLetterEmail = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfileEmail = async (event) => {
    event.preventDefault();


    let newErrors = {};


    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!phone) {
      newErrors.phone = "Please enter the Mobile Number";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setLoading2(true);
      setErrors('');

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details_Email`, {
          method: 'POST',
          body: JSON.stringify({
            studentID: studentsID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            phone,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });


        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfileEmail(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setLoading2(false);
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setPhone("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModalEmail();
          handleOpenTimeSlotModal(t_id);

        } else if (response.status === 400) {
          setLoading2(false);
          setMobileError(data.message);
        }

      } catch (error) {
        setLoading2(false);
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const handleEnterKey1 = (event) => {

    handleValue(event);

  };

  const handleKeyDown1 = () => {
    const newOtp = [...otp];
    if (otp[focusedIndex]) {
      // Clear current field
      newOtp[focusedIndex] = '';
      setOTP(newOtp);
    } else if (focusedIndex > 0) {
      // Move to the previous field if empty
      inputRefs.current[focusedIndex - 1].focus();
      setFocusedIndex(focusedIndex - 1)
    }

  };

  const handleInput1 = (key) => {
    const value = key;

    // Check if the value is a valid number
    const newOtp = [...otp];

    // Extract only the last digit of the input
    newOtp[focusedIndex] = value

    // Update the OTP state with the new value
    setOTP(newOtp);

    // Focus the next text field if available
    if (value && focusedIndex < otp.length - 1) {
      inputRefs.current[focusedIndex + 1].focus();
      setFocusedIndex(focusedIndex + 1)
    }

  };

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      const data = await response.json();
      if (response.status == 200) {
        // Update the state with the links
        setHotline(data.result.contact_num ? data.result.contact_num : '');
      } else {
        console.warn('No data found:', data.message);
      }
    } catch (err) {
      console.error('Failed to fetch system settings:', err);
    }
  };

  console.log(accBalance)

  return (
    <div className="teacher_profile">
      <Helmet>
        <title>Teacher {teacherData?.t_fname ?? ''} {teacherData?.t_lname ?? ''} - {themeConfig.templateName}</title>
        <meta name="description" content="This is the home page of your site." />
        <meta property="og:title" content="Dynamic Page Title" />
        <meta property="og:description" content="Description for this page." />
        <meta property="og:image" content="https://yourwebsite.com/dynamic-image.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/this-page" />
      </Helmet>
      {localStorage.getItem('studentID') ? <NavbarLog /> : <Navbar />}

      {teacherloading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularIndeterminate />
        </Box>
      ) : (
        <>
          {(teacherData.pro_status === 1 || teacherData.status == 0) ? (
            <Box sx={{ mt: 15, display: "flex", justifyContent: "center", mx: 2, minHeight: "100vh" }}>
              <Box>
                <Card sx={{ maxWidth: 500, p: 2, textAlign: "center", bgcolor: "#f9f9f9", boxShadow: 3, borderRadius: 2 }}>
                  <CardContent>
                    <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", gap: 1 }}>
                      <BlockIcon sx={{ fontSize: 40, color: "#d32f2f" }} />
                      <Typography variant="h6" color="text.primary">
                        This account is currently unavailable
                        {/* This account is currently in private mode */}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          ) : (
            <Container maxWidth="lg" sx={{ py: 10, minHeight: '100vh' }}>
              <Grid container spacing={2} justifyContent="center" >
                <Grid item xs={12} md={3.3} >
                  <Card variant="outlined" sx={{
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                    position: 'sticky',
                    top: '80px',
                    minHeight: '580px',
                    width: '100%',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>

                    <Grid absolute style={{
                      position: 'absolute',
                      width: '100%',
                      height: '120px',
                      background: "rgb(0,102,158)",
                      background: "linear-gradient(90deg, rgba(0,102,158,1) 0%, rgba(8,61,112,1) 53%, rgba(16,24,71,1) 100%)",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 1,
                      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)'
                    }}>
                    </Grid>

                    <Grid sx={{ mt: 4 }} >
                      <Avatar alt="Teacher" src={teacherData?.profile} sx={{ width: 150, height: 150, border: "3px solid white", borderRadius: "50% ", zIndex: 2, position: 'relative' }} />
                    </Grid>


                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                      <Typography color='black' fontSize='22px'>
                        {teacherData.t_fname} {teacherData.t_lname}
                      </Typography>

                      <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Star value={teacherData.rate} />
                      </Grid>

                      <Typography sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', m: 2, py: 2, borderBottom: 1, borderTop: 1, borderColor: '#e7e7e7', color: '#696666' }}>
                        {teacherData.edu_qualification}
                      </Typography>
                    </Grid>



                    <Grid sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'black', mb: 2 }} >
                      <Typography style={{ fontSize: 22, fontWeight: 600 }}>LKR {teacherData.t_hourrate?.toFixed(2)}</Typography>
                      <Typography style={{ fontSize: 15, fontWeight: 100, color: 'black' }}  >
                        (Per Hour)
                      </Typography>


                    </Grid >
                    <Typography
                      component="div"
                      sx={{
                        mb: 4,
                        ml: 1.5,
                        color: "white",
                        backgroundColor: "#101847",
                        borderRadius: '8px',
                        padding: '15px 35px',
                        left: '50%',
                        // position: 'absolute',
                        // bottom: '80px',
                        transform: 'translateX(-5%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: "#0d65c8",
                          //#00669e
                        },
                        '&:hover .icon': {
                          transform: 'translateX(5px)',
                          transition: 'transform 0.5s ease',
                        },
                      }}
                      onClick={() => handleButtonClick(teacherData.t_id)}
                    >
                      <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>Book Now <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                      </Box>
                    </Typography>
                  </Card>
                </Grid>

                <Grid item xs={12} md={8.7}>
                  <Card variant="outlined" sx={{
                    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                    position: 'relative',
                    width: '100%',
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start', // align top
                  }}>


                    <Grid sx={{ position: 'relative', width: '100%', paddingBottom: '56.25%', mb: 4 }}>
                      {
                        teacherData.introVideo1 ? (
                          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                            <iframe
                              // src={teacherData.introVideo1}
                              src={`${teacherData.introVideo1}?enablejsapi=1&loop=1&playlist=${teacherData.introVideo}`}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              style={{ width: '100%', height: '100%' }}
                            />
                          </div>
                        ) : (
                          <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0', color: '#666', fontSize: '18px' }}>
                            No video available
                          </div>
                        )
                      }

                    </Grid>


                    <Grid sx={{ mx: 3, mb: 1, color: 'black' }} >
                      <Typography sx={{ fontSize: 17, borderBottom: 1, borderColor: '#e7e7e7' }}>
                        Teacher Summary
                      </Typography>
                    </Grid>

                    <Grid sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', mx: 4, color: '#696666', lineHeight: 1.5 }}>
                      {teacherData.summery}
                    </Grid>

                    <Grid sx={{ mx: 3, mt: 4, mb: 1, color: 'black' }} >
                      <Typography sx={{ fontSize: 17, borderBottom: 1, borderColor: '#e7e7e7' }}>
                        I will teach
                      </Typography>
                    </Grid>

                    <Grid sx={{ maxWidth: 'lg', color: '#696666', ml: 6, mb: 4 }}>
                      <Typography>
                        {teacherData && teacherData.skills && (
                          <SkillsArrayDefine skills={teacherData.skills} />
                        )}
                      </Typography>
                    </Grid>


                  </Card>

                  {studentData.length > 0 && (
                    <Card variant="outlined" sx={{
                      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
                      position: 'relative',
                      width: '100%',
                      backgroundColor: '#fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start', // align top
                      p: 2,
                      my: 2
                    }}>

                      <Grid>
                        <Typography sx={{ fontSize: 17, mb: 1 }}>Last Reviews</Typography>
                      </Grid>
                      {loading1 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                          <CircularIndeterminate />
                        </Box>
                      ) : (

                        <Grid container maxWidth="lg" spacing={1}>
                          {/* <Typography sx={{ mt: 2, mx: 'auto' }}>There are no reviews</Typography> */}
                          {studentData.map((item) => (
                            <Grid item key={item.ssch_id} xs={12} sm={6}>
                              <Card
                                variant="outlined"
                                sx={{
                                  boxShadow: '2px 3px 10px rgb(0,0,0, 10%)',
                                  backgroundColor: '#064a7e08',
                                  borderRadius: '10px',
                                  height: '100%',
                                }}
                              >
                                <CardContent sx={{ paddingBottom: '8px !important', p: 2 }}>
                                  <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Box >
                                      <Avatar alt="User Picture" src={item?.profile} sx={{ width: 55, height: 55 }} />
                                    </Box>
                                    <Box >
                                      <Typography color="black" variant="body1" sx={{}}>
                                        {item.stu_fname} {item.stu_lname}
                                      </Typography>
                                      <Box sx={{ mt: 1 }}>
                                        <Star value={item.rate} />
                                      </Box>

                                    </Box>
                                  </Box>
                                  {item.teacher_feedback && (
                                    <Typography variant="body2" sx={{ mt: 1, fontWeight: 500, backgroundColor: '#edecec', p: 0.5, borderRadius: 1 }}>
                                      {item.teacher_feedback}
                                    </Typography>
                                  )}
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {totalItems > itemsPerPage && (
                        <TablePagination
                          component="div"
                          count={totalItems}
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={itemsPerPage}
                          rowsPerPageOptions={[]}
                        />
                      )}


                    </Card>
                  )}
                </Grid>


              </Grid>
            </Container>

          )}
        </>
      )}

      <Footer />

      <Dialog open={openMobileNumberModal} onClose={handleCloseMobileNumberModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>Login/Signup</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseMobileNumberModal}
            />
          </Tooltip>
        </Grid>



        <DialogContent>
          <DialogContentText sx={{ pb: 1 }}>
            Please Enter Your Mobile Number or Email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="mobileNumber"
            label="Mobile Number/Email"
            type="text"
            fullWidth
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            onKeyDown={handleEnter}
            error={!!errors}
            helperText={errors || ""}
            placeholder="07XXXXXXXX / XXXX@XX.XX"
            inputProps={{ autoComplete: 'off' }}
          />

        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>

          <Button
            sx={{
              size: 'large',
              type: "submit",
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0d2353",
              "&:hover": {
                backgroundColor: "#0d65c8",
              },
              "&.Mui-disabled": {
                backgroundColor: "#5BB4E4",
                color: "white",
              },
              variant: "contained",
              borderRadius: "6px",
              marginBottom: "25px",
              px: 3,
            }}
            onClick={handleCheck}
            disabled={loading}
            startIcon={loading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  color: '#fff'
                }} />
            ) : null}
          >
            {loading ? "Please Wait" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPasswordModal} onClose={handleClosePasswordModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>{enterPassword == "enter" ? "Enter Your Password" : "Set Your Password"}</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleClosePasswordModal}
            />
          </Tooltip>
        </Grid>

        {enterPassword == "enter" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Enter Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              value={password}
              error={!!errors1}
              helperText={errors1}
              onChange={handlepassword}
              onKeyDown={handleEnterPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <DialogContentText sx={{ textAlign: "right", mt: 2 }}>
              <span
                style={{
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChangeForgotPassword}
              >
                Forgot Password ?
              </span>
            </DialogContentText>

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  size: 'large',
                  type: "submit",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2,
                  px: 3,
                }}
                onClick={handleNext}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "set" ? (
          <DialogContent>

            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              type={shownewPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors2}
              helperText={errors2}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              onKeyDown={handleEnterSetPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!shownewPassword)}
                      edge="end"
                    >
                      {shownewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2,
                  px: 3,

                }}
                onClick={handleNewPasswordNextSubmit}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "emailpassowrd" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              type={showemailPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors2}
              helperText={errors2}
              value={emailpassword}
              onChange={(event) => setEmailpassword(event.target.value)}
              onKeyDown={handleEnterSetPasswordBoth}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowemailPassword(!showemailPassword)}
                      edge="end"
                    >
                      {showemailPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <DialogActions sx={{ justifyContent: "center" }}>

              <Button
                component="div"
                sx={{
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  borderRadius: "6px",
                  padding: "8px 18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  cursor: "pointer",
                  mt: 2,
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&:hover .icon": {
                    transform: "translateX(5px)",
                    transition: "transform 0.5s ease", // Smooth animation for the icon
                  },
                }}
                onClick={handleEnterEmailPassword}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                <Box component="span" sx={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
                  {loading ? "Please Wait" : "Register"}
                  <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                </Box>
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : null}

      </Dialog>

      <Dialog open={openOTPModal} onClose={handleCloseOTPModal} fullWidth
        maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>OTP Verification</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseOTPModal}
            />
          </Tooltip>
        </Grid>

        <DialogContent>
          <DialogContent sx={{ borderRadius: "6px" }}>
            <DialogContentText
              sx={{
                textAlign: "center",
                varient: "modal-body",
                color: "black",
                mb: 1,
              }}
            >
              Please enter the OTP to verify your account
            </DialogContentText>
            <DialogContentText sx={{ mb: "1", textAlign: "center" }}>
              Code has been sent to {mobileNumber}
              <span
                style={{
                  marginLeft: 5,
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChange}
              >
                Change
              </span>
            </DialogContentText>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                flexDirection: "row",
                marginTop: "20px",
                gap: 7
              }}
            >
              {lgAbove ? (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        // readOnly: true, 
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              ) : (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        readOnly: true,
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              )}

              {/* {otp.map((digit, index) => (
                <TextField
                  key={index}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleInput(e, index)}
                  onClick={() => handleClick(index)}
                  onKeyDown={(e) => {
                    handleKeyDown(index, e);
                    handleEnterKey(e);
                  }}
                  inputProps={{
                    inputMode: 'none', // Show numeric keypad on mobile
                    // readOnly: true, 
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                      height: 40, // Ensures the input height
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid gray", // Adjust border
                    },
                    "& .MuiInputBase-input": {
                      padding: 0, // Remove inner padding for precise size
                      textAlign: "center", // Centers the text
                    },
                  }}
                />
              ))} */}
            </Grid>
            {errors && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Typography
                  variant="caption"
                  sx={{ color: "red", textAlign: "center", fontSize: "14px" }}
                >
                  {errors}
                </Typography>
              </Box>
            )}

            <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#F1F1F1', borderRadius: 1, mt: 2, display: { xs: 'block', lg: 'none' } }}>
              <Grid container spacing={1}>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Clear'].map((key) => (
                  <Grid item xs={key === 'Clear' ? 8 : 4} key={key}>
                    <Button
                      variant="contained"
                      // color={key === 'Clear' ? 'error' : key === 'Enter' ? 'success' : 'primary'}
                      sx={{ backgroundColor: '#cfd9ddd1', ":hover": { backgroundColor: '#cfd9ddd1' }, color: '#413e3e', fontSize: '16px', minHeight: '40px' }}
                      fullWidth
                      onClick={() =>
                        key === 'Clear' ? handleKeyDown1() : key === 'Enter' ? handleEnterKey1() : handleInput1(key)
                      }
                    >
                      {key === 'Clear' ? <Icon icon="material-symbols-light:backspace" width="24" height="24" /> : key === 'Enter' ? <Icon icon="fluent:arrow-enter-left-24-regular" width="24" height="24" style={{ color: 'black' }} /> : key}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <DialogActions sx={{ justifyContent: "center" }}>
              {/* <Button onClick={handleCloseOTP}>Cancel</Button>
          <Button component={Link} to="/dashboard" variant="contained" color="primary">Submit</Button> */}

              <Button
                sx={{
                  type: "submit",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  mt: 2,
                  px: 4,
                  py: 1,
                  borderRadius: "6px",
                }}
                onClick={handleValue}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Verify"}
              </Button>
            </DialogActions>
          </DialogContent>
          <DialogContentText sx={{ my: 2, justifyContent: "center", textAlign: "center" }}>
            Didn't get the code?
            {countdown > 0 ? (
              <span style={{ marginLeft: 5, color: "gray" }}> Try Again in {countdown}s</span>
            ) : (
              <span
                style={{ marginLeft: 5, color: "rgb(13 110 253)", cursor: "pointer" }}
                onClick={() => {
                  handleSend();
                  setCountdown(60);
                }}
              >
                Try Again
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>

      <Dialog open={openTimeSlotModal} onClose={handleCloseTimeSlotModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', background: 'linear-gradient(to right , #101847, #00669e)', color: 'white' }}>
          <DialogTitle>Schedule a class</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTimeSlotModal} />
          </Tooltip>
        </Grid>

        <DialogContent>
          <Stack direction="row" justifyContent='space-between' >
            <Grid container spacing={2} alignItems="center">
              <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar src={teacher?.profile} alt="Teacher" sx={{ width: 100, height: 100 }} />
              </Grid>
              <Grid item>
                <Typography color='black'>{teacher.t_fname} {teacher.t_lname}</Typography>
              </Grid>
            </Grid>


            <Grid item container direction="column" justifyContent="center" alignItems="flex-end"  >
              <Grid item >
                <Star value={teacher.rate} />
              </Grid>

              <Grid item sx={{ mr: 2 }}>
                <Typography>LKR {teacher.t_hourrate?.toFixed(2)}</Typography>
                <Typography mt="6px" style={{ fontSize: 14, fontWeight: 100 }}>Per Hour</Typography>
              </Grid>
            </Grid>

          </Stack>


          {/* <DialogContentText sx={{ mt: 1 }}>Select Date?</DialogContentText> */}
          {availableDate.length <= 0 ? (
            <Box sx={{ mt: 2 }}>
              <Alert icon={<InfoOutlinedIcon fontSize="inherit" />} variant="outlined" severity="warning">
                The teacher is currently unavailable.
              </Alert>
            </Box>
          ) : (

            <FormControl fullWidth>
              <Select sx={{ mt: 2 }} value={date} onChange={handleDateChange} displayEmpty>
                <MenuItem value="" disabled>Please Select A Date</MenuItem>
                {availableDate.map((item, index) => (
                  <MenuItem key={item.schedule_date} value={item.schedule_date}>
                    {new Date(item.schedule_date).toLocaleDateString("en-CA")}
                  </MenuItem>
                ))}
              </Select>
              {errors && (
                <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                  {errors}
                </Typography>
              )}
            </FormControl>
          )}

          {date && (
            <>
              <DialogContentText sx={{ mt: 2 }}>Select Time Slots (Sri Lankan Time - GMT+5:30)</DialogContentText>
              <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                <Grid container spacing={1}>
                  {availableTime.map((item, index) => (
                    <Grid item key={index} >
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{
                          backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#00669e' : 'transparent',
                          color: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? 'white' : 'black',
                          '&:hover': {
                            backgroundColor: selectedSlots.find(slot => slot.start_time === item.start_time && slot.end_time === item.end_time) ? '#005bb5' : '#e0e0e0'
                          }
                        }}

                        onClick={() => {
                          handleTimeChange();
                          handleSelectTimeSlot(item);
                        }}
                      >
                        {item.start_time} - {item.end_time}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {errors1 && (
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 2 }}>
                    {errors1}
                  </Typography>
                )}
              </FormControl>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          {availableDate.length > 0 && (
            <Button
              sx={{
                type: "submit",
                dispaly: 'flex',
                justifyContent: 'center',
                color: 'white',
                backgroundColor: '#0d2353',
                '&:hover': { backgroundColor: '#0d65c8' },
                variant: 'contained',
                borderRadius: '6px',
                mb: 2.5,
                px: 2,
              }}
              onClick={() => handleopenAccountBalanceModal()}
            >
              Schedule a class
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <CustomDialog open={openAccountBalanceModal} onClose={handleCloseAccountBalanceModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Close" >
            <CloseIcon sx={{ mt: 1, mr: 1, cursor: 'pointer', border: '1px solid black', borderRadius: 5, width: '35px', height: '35px' }} onClick={handleCloseAccountBalanceModal} />
          </Tooltip>
        </Grid>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <Grid container sx={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ color: '#101847', fontSize: 18 }}>
              Review and Confirm Your Booking Details
              <Divider sx={{ p: 0.5, mb: 3 }} />
            </Typography>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Date:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{schedule_date}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Schedule Time:</Typography>
              <Typography sx={{ fontSize: '0.8rem', color: 'gray' }}>(Total Hours:{accBalance.totalHours})</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              {selectedSlots.map((slot, index) => (
                <Typography key={index}>
                  {slot.start_time} - {slot.end_time}
                </Typography>
              ))}
            </Grid>


            <Grid item xs={6} sx={{ mb: 2 }}>
              <Typography>Per Hour Rate:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
              <Typography>{currency} {Number(accBalance.t_hourrate)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6} sx={{ my: 2 }}>
              <Typography>Total Fee:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right', mt: 2 }} >
              <Typography sx={{ borderBottom: 1, borderTop: 1 }}>{currency} {Number(accBalance.totalAmount)?.toFixed(2)}
                <Grid container justifyContent="flex-end">
                </Grid>
              </Typography>
            </Grid>

            <Grid item xs={6}  >
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(accBalance.acc_balance)?.toFixed(2)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>New Account Balance:</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Typography sx={{ fontSize: '0.9rem', color: 'gray' }}>{currency} {Number(newAccountBalance)?.toFixed(2)}</Typography>
            </Grid>

          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          {accBalance.acc_balance >= accBalance.totalAmount ? (
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', sm: 'row' }, padding: 2 }}>
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  display: { xs: 'none', sm: 'block' },
                  border: '2px solid #0d65c8',
                  borderColor: '#0d65c8',
                  borderRadius: '6px',
                  px: 2,
                }}

                onClick={handleChangeSheduleDate}
              >
                Change Slots
              </Button>

              <Button
                type="submit"
                sx={{
                  color: 'white',
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: 'contained',
                  borderRadius: '6px',
                  px: 2,
                }}
                onClick={handleSubmit}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Confirm Your Class"}
              </Button>

              <Typography
                variant="body2"
                color="primary"
                sx={{ cursor: 'pointer', display: { xs: 'block', sm: 'none' }, textAlign: 'center' }}
                onClick={handleChangeSheduleDate}
              >
                Change Slots
              </Typography>

            </Box>
          ) : (
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Typography sx={{ textAlign: 'center', color: 'orange', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 0.5 }}>
                  <ErrorOutlineIcon sx={{ color: 'orange', fontSize: '1.3rem' }} />
                  Your account balance is insufficient. Please Top up your account.
                </Typography>
              </Grid>

              <Grid container direction="row" justifyContent="center" gap={2} p={2}>
                <Button
                  type="submit"
                  variant="outlined"
                  style={{
                    border: '2px solid #0d65c8',
                    borderColor: '#0d65c8',
                    borderRadius: '6px',
                    px: 2,
                  }}

                  onClick={handleChangeSheduleDate}
                >
                  Change Slots
                </Button>

                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: '#0d2353',
                    '&:hover': { backgroundColor: '#0d65c8' },
                    variant: 'contained',
                    borderRadius: '6px',
                  }}
                  onClick={() => handleOpenTopUpModal(Math.abs(newAccountBalance))}
                >
                  Top-up
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogActions>
      </CustomDialog>

      <Dialog open={openTopUpModal} onClose={handleCloseTopUpModal} fullWidth>
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
          <DialogTitle>Top-Up your Account</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mt: 2.5, mr: 1, cursor: 'pointer' }} onClick={handleCloseTopUpModal} />
          </Tooltip>
        </Grid>


        <DialogContent sx={{ paddingTop: 1 }}>
          <Card xs={12} sx={{ borderRadius: 2, boxShadow: 2, width: '100%' }}>
            <CardContent>

              <Box
                sx={{
                  width: '100%',
                  background: '#fafafa',
                  borderRadius: 2,
                  boxShadow: 'inset 0px 0px 3px rgba(0, 0, 0, 0.2)',
                  p: 1.2,
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: 14, sm: 20 },
                    fontWeight: '500',
                    color: 'black',
                  }}
                >
                  Account Balance : {accBalance.acc_balance}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: { xs: 12, sm: 15 },
                      fontWeight: '500',
                      color: '#888885',
                    }}
                  >
                    {currency}
                  </Typography>
                </Typography>
              </Box>

              <Grid container spacing={2} sx={{ mt: 1.5 }} >
                <Grid item xs={12} sm={6} >
                  <TextField type='text' inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }} label="Amount" size="large" value={amount} onChange={handleAmountChange} onKeyDown={handleEnterPay} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} >
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ border: '1px solid black', borderRadius: 1, backgroundColor: '#101847', height: 56 }}
                    onClick={(event) => handleSubmitPay(event, studentID, amount)}
                    fullWidth
                  >
                    Pay with card
                  </Button>
                </Grid>

                {topuperrors ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 0, marginLeft: '16px' }}>
                    {topuperrors}
                  </Typography>
                  : null
                }

                <Grid item xs={12} sx={{ mt: 1.5, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="https://www.payhere.lk" target="_blank" rel="noopener noreferrer">

                    <Box sx={{ display: 'flex' }} >
                      <img src="https://box1.ozonedesk.info/upload/27Nov2024074045_PayHere-Logo.png" alt="PayHere" style={{ maxWidth: '70px', height: 'auto', marginRight: 5 }} />
                      <Icon icon="logos:visa" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="logos:mastercard" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="simple-icons:americanexpress" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                      <Icon icon="noto:bank" width="1.6rem" height="1.4rem" style={{
                        marginRight: 5, border: '2px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }} />
                    </Box>
                  </a>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>

      <Dialog open={openProfileModal} onClose={handleCloseProfileModal} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems: 'center' }}>
          <DialogTitle sx={{ ml: 1, fontSize: { xs: '17px', sm: '20px' } }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mr: 3, cursor: 'pointer' }} onClick={handleCloseProfileModal} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfile}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstname}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastname}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={email}
                  onChange={handleemail}
                />
                {errors.email ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.email}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddress}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecity}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  value={country}
                  onChange={handlecountry}
                  options={countries}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  ListboxProps={{
                    sx: {
                      maxHeight: '200px', // Set height for dropdown content
                      overflowY: 'auto', // Enable scrolling only inside the list
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item
                mb={1}
                xs={12}
                mt={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#0d2353",
                    "&:hover": {
                      backgroundColor: "#0d65c8",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#5BB4E4",
                      color: "white",
                    },
                  }}
                  size="md"
                  disabled={loading2}
                  startIcon={loading2 ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        color: '#fff'
                      }} />
                  ) : null}
                >
                  {loading2 ? "Please Wait" : "Sign up"}
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>
            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>

      <Dialog open={openUserDetailsEmail} onClose={handleCloseUserDetailsEmail} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems: 'center' }}>
          <DialogTitle sx={{ ml: 1, fontSize: { xs: '17px', sm: '20px' } }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mr: 3, cursor: 'pointer' }} onClick={handleCloseUserDetailsEmail} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfileEmail}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstnameEmail}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastnameEmail}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }

              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Mobile Number"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={phone}
                  onChange={handlePhone}
                />
                {errors.phone ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.phone}
                  </Typography> : null
                }
                {mobileError ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {mobileError}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddressEmail}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecityEmail}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={countries}
                  value={country}
                  onChange={handlecountry}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item
                mb={1}
                mt={3}
                xs={12}
                sx={{

                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#0d2353",
                    "&:hover": {
                      backgroundColor: "#0d65c8",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#5BB4E4",
                      color: "white",
                    },
                  }}
                  size="md"
                  disabled={loading2}
                  startIcon={loading2 ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        color: '#fff'
                      }} />
                  ) : null}
                >
                  {loading2 ? "Please Wait" : "Sign up"}
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>



    </div>
  );
}

export default TeacherProfile;