import React, { useState, forwardRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import { Avatar, Box } from '@mui/material'
import Swal from 'sweetalert2'
import TextField from '@mui/material/TextField'
import { CircularProgress, Chip, Card, CardContent, Divider, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAvatar from '@mui/material/Avatar'

import { useTheme } from '@mui/material/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImagePreview from './ImagePreview'
import Grid from '@mui/material/Grid'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { getInitials } from '../name'
import { useNavigate, useParams } from 'react-router-dom';
import NavbarLog from '../navbarlogged'
import Navbar from '../navbar'
import Footer from '../footer'
import RandomTeachers from './RandomTeachers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Helmet } from "react-helmet";
import themeConfig from '../../config/themeConfig'
import { Icon } from '@iconify/react/dist/iconify.js'

const OneForumView = () => {
  const [post, setPost] = useState(null)
  const [imageUrl, setImageUrl] = useState([])
  const [imagePreview, setImagePreview] = useState(false)
  const [likesArray, setLikesArray] = useState([])
  const [subComments, setSubComments] = useState([])
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [subPage, setSubPage] = useState(1)
  const [message, setMessage] = useState('')
  const [totalComments, setTotalComments] = useState(0)
  const [randomTeacherP, setRandomTeacherP] = useState([])
  const [banner, setBanner] = useState([])

  const { id } = useParams();

  const updateMetaTags = (ogTitle, ogDescription, ogImage, ogUrl) => {

    const ogTitleTag = document.querySelector('meta[property="og:title"]');
    if (ogTitleTag) ogTitleTag.setAttribute('content', ogTitle);

    const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
    if (ogDescriptionTag) ogDescriptionTag.setAttribute('content', ogDescription);

    const ogImageTag = document.querySelector('meta[property="og:image"]');
    if (ogImageTag) ogImageTag.setAttribute('content', ogImage);

    const ogUrlTag = document.querySelector('meta[property="og:url"]');
    if (ogUrlTag) ogUrlTag.setAttribute('content', ogUrl);
  };

  useEffect(() => {
    fetchRandomTeacher()
    fetchonePost(id)
    fetchSubMessage(id);
    fetchBanner()
  }, [id])


  useEffect(() => {
    if (subPage > 1) {
      fetchSubMessage(id);
    }
  }, [subPage]);

  let adminId = ''
  if (typeof localStorage !== 'undefined') {
    adminId = window.localStorage.getItem('studentID')
  }

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  const handleImagePreview = img => {
    setImagePreview(true)
    setImageUrl(img)
  }

  const fetchonePost = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/one-post`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          id: id
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setPost(data.result)
        if(data.result.type == 'teacher'){
          updateMetaTags(
            `${data.result.additionalInfo?.t_fname} ${data.result.additionalInfo?.t_lname}`, // Open Graph title
            `${data.result.title}`, // Open Graph description
            `${data.result.additionalInfo?.profile}`, // Open Graph image URL
            "https://englishteacher.lk/" // Open Graph URL
          );
        } else {
          updateMetaTags(
           `${data.result.additionalInfo?.stu_fname} ${data.result.additionalInfo?.stu_lname}`, // Open Graph title
            `${data.result.title}`, // Open Graph description
            `${data.result.additionalInfo?.profile}`, // Open Graph image URL
            "https://englishteacher.lk/" // Open Graph URL
          );
        }
      } else {
        setPost(null)
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchSubMessage = async (id) => {
    setIsLoadingComments(true)
    console.log(subPage)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/sub-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({
          post_id: id,
          page: subPage
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setSubComments(prevComments => [...prevComments, ...data.result])
        setIsLoadingComments(false)
        setTotalComments(data.totalRecords)
      } else {
        setIsLoadingComments(false)
        setSubComments(prevComments => [...prevComments])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleCommentPage = () => {
    setSubPage(subPage + 1)
  }

  const fetchRandomTeacher = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/random-teacher`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      })

      if (response.status == 200) {
        const data = await response.json()
        setRandomTeacherP(data.results)
      } else {
        setRandomTeacherP([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchBanner = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/banner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      })

      if (response.status == 200) {
        const data = await response.json()
        setBanner(data.result)
      } else {
        setBanner([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const cleanTextForUrl = (text) => {
    // Strip HTML tags

    const stripHtmlTags = (html) => {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.innerText || tempDiv.textContent || '';
    };

    // Get plain text without HTML tags
    const plainText = stripHtmlTags(text);

    // Encode special characters and replace spaces with hyphens
    return plainText
  };

  return (
    <>
      <Helmet>
        <title>{post?.title ? post?.title : 'Community'} - {themeConfig.templateName}</title>
        <meta
          name="description"
          content={post?.text.length > 9 ? cleanTextForUrl(post?.text) : "This is the community page of your site."}
          key={post?.text.length > 9 ? cleanTextForUrl(post?.text) : "defaultDescription"}
        />
      </Helmet>
      {localStorage.getItem('studentID') ? <NavbarLog /> : <Navbar />}
      <Container maxWidth="lg" sx={{ mb: 15, mt: 13 }}  >

        <Box sx={{ display: 'flex', gap: 5 }}>

          <Box sx={{ flexGrow: 1 }}>

            <Card sx={{ boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>

              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box>
                    {post?.type == 'student' ? (
                      <>
                        {post?.additionalInfo?.profile ? (
                          <MuiAvatar
                            src={post?.additionalInfo?.profile}
                            alt={`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <MuiAvatar
                            skin='light'
                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                          >
                            {getInitials(`${post.additionalInfo?.stu_fname} ${post.additionalInfo?.stu_lname}`)}
                          </MuiAvatar>
                        )}
                      </>
                    ) : post?.type == 'teacher' ? (
                      <>
                        {post?.additionalInfo?.profile ? (
                          <MuiAvatar
                            src={post?.additionalInfo?.profile}
                            alt={`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`}
                            sx={{ width: 40, height: 40 }}
                          />
                        ) : (
                          <MuiAvatar
                            skin='light'
                            sx={{ width: 40, height: 40, fontSize: '1rem', textTransform: 'uppercase' }}
                          >
                            {getInitials(`${post.additionalInfo?.t_fname} ${post.additionalInfo?.t_lname}`)}
                          </MuiAvatar>
                        )}
                      </>
                    ) : (
                      <MuiAvatar src='/images/et_logo.png' alt='admin' sx={{ width: 40, height: 40 }} />
                    )}
                  </Box>
                  <Box>
                    {post?.type == 'student' && (
                      <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                        {post.additionalInfo ? post.additionalInfo?.stu_fname : ''}{' '}
                        {post.additionalInfo ? post.additionalInfo?.stu_lname : ''}
                      </Typography>
                    )}
                    {post?.type == 'teacher' && (
                      <>
                        <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                          {post.additionalInfo ? post.additionalInfo?.t_fname : ''}{' '}
                          {post.additionalInfo ? post.additionalInfo?.t_lname : ''}
                        </Typography>
                        {post.type == 'teacher' && (
                          <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}>Teacher</Typography>
                        )}
                      </>
                    )}
                    {post?.type == 'admin' && (
                      <Typography sx={{ fontSize: 14, fontWeight: 600, textTransform: 'capitalize' }}>
                        EnglishTeacher.lk
                      </Typography>
                    )}
                  </Box>
                </Box>




                <Divider sx={{ mt: 1, mb: 3 }} />


                {post?.title && post.type == 'student' ? (
                  <Typography sx={{ fontSize: '30px', fontWeight: 600, }} >
                    <Icon icon="clarity:help-solid-badged" width="1.4rem" height="1.4rem" style={{ marginRight: 5 }} />

                    {post?.additionalInfo?.stu_fname} {post?.title}
                  </Typography>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }} >
                    <Typography sx={{ fontSize: '30px', fontWeight: 600 }}>{post?.title}</Typography>

                  </Box>
                )}

                {post?.text && (
                  <Box
                    className="post-content"
                    dangerouslySetInnerHTML={{ __html: post.text }}
                    sx={{
                      img: {
                        maxWidth: '100%', // Prevent image overflow
                        height: 'auto',   // Maintain aspect ratio
                      },
                      iframe: {
                        maxWidth: '100%', // Make the video responsive
                        height: 'auto', // Maintain the aspect ratio
                        minHeight: '300px', // Set a minimum height for better appearance
                      },
                      borderRadius: '8px',
                      backgroundColor: '#f7f4f4'
                    }}
                  ></Box>
                )}

                {post?.img.length == 1 ? (
                  <Box
                    sx={{
                      width: '100%',
                      paddingBottom: '56.25%',
                      mt: 1,
                      borderRadius: 0.5,
                      backgroundColor: '#dbdbdb',
                      position: 'relative',
                      overflow: 'hidden',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleImagePreview(post?.img)}
                  >
                    <img
                      src={post?.img[0]}
                      alt='post'
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: 1
                      }} // Ensure image fills the container
                    />
                  </Box>
                ) : post?.img.length > 1 ? (
                  <Box sx={{ width: '100%', mt: 1 }}>
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      {post?.img.map((item, index) => (
                        <Grid item xs={12} sm={6} key={index} onClick={() => handleImagePreview(post?.img)}>
                          <img
                            src={item}
                            alt={index}
                            loading='lazy'
                            style={{ objectFit: 'cover', width: '100%', height: 200, cursor: 'pointer' }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ) : null}

                {post?.url2 && (
                  <Box
                    className='youtube-container'
                    sx={{
                      position: 'relative',
                      paddingBottom: '56.25%',
                      height: 0,
                      overflow: 'hidden',
                      maxWidth: '100%',
                      background: '#000',
                      mt: 1
                    }}
                  >
                    <iframe
                      title='YouTube video'
                      src={post.url2}
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    />
                  </Box>
                )}

                <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1, mt: 1.5 }}>
                  <Chip icon={<ChatBubbleOutlineOutlinedIcon />} label={post?.comments} />
                  <Chip
                    icon={<FavoriteBorderOutlinedIcon />}
                    label={post?.det_like}
                  />
                </Box>
              </CardContent>

              <CardContent>
                <Typography variant='h6'>
                  Comments - {totalComments}
                </Typography>
              </CardContent>

              {subComments.length > 0 && (
                <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                  {subComments.map((comments, index) => (
                    <Box key={index}>
                      {comments.comment && (
                        <Box sx={{ px: '20px' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Box>
                              {comments?.type == 'student' ? (
                                <>
                                  {comments?.userDetails?.profile ? (
                                    <MuiAvatar
                                      src={comments?.userDetails?.profile}
                                      alt={`${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`}
                                      sx={{ width: 36, height: 36 }}
                                    />
                                  ) : (
                                    <MuiAvatar
                                      skin='light'
                                      sx={{ width: 36, height: 36, fontSize: '1rem', textTransform: 'uppercase' }}
                                    >
                                      {getInitials(
                                        `${comments.userDetails?.stu_fname} ${comments.userDetails?.stu_lname}`
                                      )}
                                    </MuiAvatar>
                                  )}
                                </>
                              ) : comments?.type == 'teacher' ? (
                                <>
                                  {comments?.userDetails?.profile ? (
                                    <MuiAvatar
                                      src={comments?.userDetails?.profile}
                                      alt={`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`}
                                      sx={{ width: 36, height: 36 }}
                                    />
                                  ) : (
                                    <MuiAvatar
                                      skin='light'
                                      sx={{ width: 36, height: 36, fontSize: '1rem', textTransform: 'uppercase' }}
                                    >
                                      {getInitials(`${comments.userDetails?.t_fname} ${comments.userDetails?.t_lname}`)}
                                    </MuiAvatar>
                                  )}
                                </>
                              ) : (
                                <MuiAvatar src='/images/et_logo.png' alt='admin' sx={{ width: 36, height: 36 }} />
                              )}
                            </Box>
                            <Box>
                              {comments?.type == 'student' && (
                                <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                  {comments.userDetails ? comments.userDetails?.stu_fname : ''}{' '}
                                  {comments.userDetails ? comments.userDetails?.stu_lname : ''}
                                </Typography>
                              )}
                              {comments?.type == 'teacher' && (
                                <>
                                  <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                    {comments.userDetails ? comments.userDetails?.t_fname : ''}{' '}
                                    {comments.userDetails ? comments.userDetails?.t_lname : ''}
                                  </Typography>
                                  {comments.type == 'teacher' && (
                                    <Typography sx={{ fontSize: 12, fontWeight: 600, textTransform: 'capitalize', color: '#4c4e6499' }}>Teacher</Typography>
                                  )}
                                </>
                              )}
                              {comments?.type == 'admin' && (
                                <Typography sx={{ fontSize: 16, fontWeight: 600, textTransform: 'capitalize' }}>
                                  EnglishTeacher.lk
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Typography sx={{ mt: 0.5, fontSize: 16, fontWeight: 500, ml: 5 }}>{comments.comment}</Typography>
                        </Box>
                      )}
                    </Box>
                  ))}
                </CardContent>
              )}

            </Card>

            {isLoadingComments && (
              <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <CircularProgress sx={{ mb: 4 }} />
              </Box>
            )}

            {totalComments > subComments.length && (
              <Button
                variant='contained'
                size='small'
                sx={{ mt: 1 }}
                startIcon={<KeyboardArrowDownIcon />}
                onClick={() => handleCommentPage()}
              >
                view more comments
              </Button>
            )}
          </Box>

          <Box
            sx={{
              backgroundColor: '',
              maxWidth: '300px',
              width: '300px',
              display: { xs: 'none', md: 'block' }
            }}
          >
            {post?.timeslot ? (
              <Box sx={{
                maxWidth: '300px',
                width: '300px',
              }}>
                <RandomTeachers teacher={post?.additionalInfo} />
              </Box>
            ) : (
              <Box sx={{
                display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '300px',
                width: '300px',
              }}>
                {randomTeacherP.map((data, index) => (
                  <RandomTeachers teacher={data} key={index} />
                ))}
              </Box>
            )}

            <Box sx={{
              maxWidth: '300px',
              width: '300px', mt: 1,
              position: 'sticky',
              top: 80
            }}>
              {banner.map((data, index) => (
                <img
                  key={index}
                  src={data.url}
                  alt='post'
                  style={{ width: '100%', height: 'auto', objectFit: 'contain', }} // Ensure image fills the container
                />
              ))}
            </Box>

          </Box>

        </Box>


      </Container>

      <Footer />
      <ImagePreview imagePreview={imagePreview} setImagePreview={setImagePreview} imgUrls={imageUrl} />

    </>
  )
}

export default OneForumView
