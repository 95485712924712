// src/components/NotificationSetup.js
import React, { useEffect } from 'react';

const NotificationSetup = () => {
  const PUBLIC_VAPID_KEY = 'BHvjHpHMtVBT7s5yxLFbxSqjWpZBJ6uCP_0q3mFUGlKXE2_9S2Z3K-Kl88DOfd1sfHVaB37dgIgVof3HjH1YoxY'; // Replace with your VAPID public key

  const token = typeof localStorage !== 'undefined' ? window.localStorage.getItem('token') : null;


  useEffect(() => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      // Register the service worker
      navigator.serviceWorker.register('/sw.js').then((registration) => {
        console.log('Service Worker registered:');

        // Request permission for notifications
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            // Subscribe to push notifications
            registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(PUBLIC_VAPID_KEY),
            }).then((subscription) => {
              console.log('Push Subscription:');

              // Send subscription to the server
              fetch(`${process.env.REACT_APP_BACKEND_URL}/subscribe`, {
                method: 'POST',
                body: JSON.stringify({
                  subscription,
                  token
                }),
                headers: {
                  'Content-Type': 'application/json',
                  // 'Authorization': `Bearer ${token}`
                },
              });
            }).catch((error) => {
              console.error('Failed to subscribe:', error);
            });
          }
        });
      });
    }
  }, []);

  // Helper function to convert VAPID key to Uint8Array
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map((char) => char.charCodeAt(0)));
  };

  return null; // This component does not render anything
};

export default NotificationSetup;
