import React, { useState, forwardRef, useEffect, useRef } from 'react'
import { CircularProgress, Typography, Chip, Card, CardContent, Box, TextField, Button, Fab, Zoom, Avatar, Divider, Grow } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { Icon } from '@iconify/react';
import PhotoIcon from '@mui/icons-material/Photo';
import ArticleIcon from '@mui/icons-material/Article';
import TodayIcon from '@mui/icons-material/Today';
import ForumCard from './ForumCard';
import CommentPopup from './CommentPopup';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { useInView } from 'react-intersection-observer'
import RandomTeachers from './RandomTeachers';
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from '@mui/material/styles';

import { Link } from "react-router-dom";
import {
  AppBar,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Toolbar,
  Menu,
  Grid,
} from "@mui/material";
import Swal from 'sweetalert2';
import MenuIcon from "@mui/icons-material/Menu";
import GroupsIcon from "@mui/icons-material/Groups";
import "@fontsource/roboto/500.css";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import Drawer from '@mui/material/Drawer';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import Person2Icon from '@mui/icons-material/Person2';
import loginPage from '../loginPage/LoginPage'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from "@mui/material";
import UserDetails from '../loginPage/UserDetails'
import { NavLink } from "react-router-dom";
import { NativeSelect } from '@mui/material';
import Popper from '@mui/material/Popper';
import Autocomplete from '@mui/material/Autocomplete';
import useLogout from '../../hooks/logouthook';
import { useParams } from 'react-router-dom';


import DescriptionIcon from '@mui/icons-material/Description';
import useSubscribe from '../../hooks/subscribehook';

function CustomPopper(props) {
  return <Popper {...props} placement="top-start" />;
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '5px solid #00669e',
  },
}));

const ForumMain = () => {
  const [tableData, setTableData] = useState([])
  const [totalPost, setTotalPost] = useState(0)
  const [subComments, setSubComments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingComments, setIsLoadingComments] = useState(false)
  const [page, setPage] = useState(1);
  const [commentOpen, setCommentOpen] = useState(false)
  const [forumObject, setForumObject] = useState(null)
  const [subPage, setSubPage] = useState(1)
  const [totalComments, setTotalComments] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [banner, setBanner] = useState([])
  const [randomTeacherP, setRandomTeacherP] = useState([])
  const [otherBanner, setOtherBanner] = useState([])

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [openMobileNumberModal, setOpenMobileNumberModal] = React.useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [errors1, setErrors1] = useState("");
  const [otp, setOTP] = useState(new Array(4).fill(""));
  const [studentID, setStudentID] = useState("");
  const [openOTPModal, setOpenOTPModal] = React.useState(false);
  const [openPasswordModal, setOpenPasswordModal] = React.useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const navigate = useNavigate("");
  const inputRefs = useRef([]);
  const [stu_fname, setStu_fname] = useState("");
  const [stu_lname, setStu_lname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("Sri Lanka");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [mobileOrEmail, setMobileOrEmail] = useState('mobile')
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [enterPassword, setEnterPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [renewPassword, setReNewPassword] = useState('');
  const [shownewPassword, setShowNewPassword] = useState('');
  const [showrenewPassword, setShowReNewPassword] = useState('');
  const [newpasswordError, setNewPasswordError] = useState('');
  const [emailpassword, setEmailpassword] = useState('');
  const [showemailPassword, setShowemailPassword] = useState(false);
  const [openUserDetailsEmail, setOpenUserDetailsEmail] = useState(false);
  const [emailPassworderror, setEmailPassworderror] = useState('');
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const { handleLogout } = useLogout();
  const studentsID = localStorage.getItem('studentID');
  const [phone, setPhone] = useState("");
  const [mobileError, setMobileError] = useState("");
  const { t_id: urlT_id } = useParams();
  const [t_id, setT_id] = useState(urlT_id || "");
  const [errors2, setErrors2] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [countdown, setCountdown] = useState(60);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [hotline, setHotline] = useState('')

  const theme = useTheme()
  const lgAbove = useMediaQuery(theme.breakpoints.up("lg"));
  const {handlepushNotification} = useSubscribe()

  let adminId = ''
  if (typeof localStorage !== 'undefined') {
    adminId = window.localStorage.getItem('userID')
  }

  let token = ''
  if (typeof localStorage !== 'undefined') {
    token = window.localStorage.getItem('token')
  }

  // Intersection Observer
  const { ref, inView } = useInView({
    triggerOnce: false, // Keep triggering as the element comes into view
    threshold: 1.0 // Element should be fully visible
  })

  useEffect(() => {
    fetchRandomTeacher()
    fetchBanner()
    fetchForumCount()
    fetchOtherBanner()
    fetchSocialLinks()
  }, [])


  useEffect(() => {
    if (inView) fetchForums()
  }, [inView])

  useEffect(() => {
    if (subPage === 0) {

      return
    }

    fetchSubMessage(forumObject?.fro_id)
  }, [subPage])

  const fetchForums = async () => {

    if (isLoading || !hasMore) return

    setIsLoading(true)

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          page: page,
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setTableData((prevData) => [...prevData, ...data.result])
        setHasMore(data.result.length > 0) // Assuming no more data when API returns an empty array
        setPage(prevPage => prevPage + 1)
        setIsLoading(false)
      } else {
        setTableData((prevData) => [...prevData])
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error:', error)
    }

  }

  const fetchSubMessage = async (id) => {
    setIsLoadingComments(true)
    console.log(subPage)
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/sub-comment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          post_id: id,
          page: subPage
        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setSubComments(prevComments => [...prevComments, ...data.result])
        setIsLoadingComments(false)
        setTotalComments(data.totalRecords)
      } else {
        setIsLoadingComments(false)
        setSubComments(prevComments => [...prevComments])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleCommentClick = (post) => {
    setCommentOpen(true)
    if (post) {
      setForumObject(post)
    }
    fetchSubMessage(post.fro_id)

  };

  const fetchForumCount = async () => {

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/forum-count`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({

        })
      })
      if (response.status == 200) {
        const data = await response.json()
        setTotalPost(data.result)

      } else {

      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleCommentPage = () => {
    setSubPage(subPage + 1)
  }

  const fetchBanner = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/banner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      })

      if (response.status == 200) {
        const data = await response.json()
        setBanner(data.result)
      } else {
        setBanner([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchRandomTeacher = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/random-teacher`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      })

      if (response.status == 200) {
        const data = await response.json()
        setRandomTeacherP(data.results)
      } else {
        setRandomTeacherP([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const fetchOtherBanner = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/post/other-banner`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      })

      if (response.status == 200) {
        const data = await response.json()
        setOtherBanner(data.result)
      } else {
        setOtherBanner([])
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  //Login Process

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // mobileNumberModal

  const handleOpenMobileNumberModal = () => {
    setOpenMobileNumberModal(true);
  };

  const handleCloseMobileNumberModal = () => {
    setOpenMobileNumberModal(false);
    setErrors("");
    setMobileNumber("");
  };

  const handleMobileNumberChange = (event) => {
    let value = event.target.value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$/;
    setErrors("");

    if (!value) {
      setErrors("");
    }
    else if (!/^\+?[\d]+$/.test(value) && !emailRegex.test(value)) {
      setErrors("Invalid mobile number or email.");
    }
    else if (/^\+?[\d]+$/.test(value)) {
      if (value.startsWith("7")) {
        if (value.length !== 9) {
          setErrors("Invalid mobile number. Must be 9 digits when starting with '7'.");
        }
      } else if (value.startsWith("07")) {
        if (value.length !== 10) {
          setErrors("Invalid mobile number. Must be 10 digits when starting with '07'.");
        }
      } else if (value.startsWith("947")) {
        if (value.length !== 11) {
          setErrors("Invalid mobile number. Must be 11 digits when starting with '947'.");
        }
      } else if (value.startsWith("+947")) {
        if (value.length !== 12) {
          setErrors("Invalid mobile number. Must be 12 digits when starting with '+947'.");
        }
      } else {
        setErrors("Invalid mobile number format.");
      }
    }

    setMobileNumber(value);
  };

  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  const mobileRegex = /^\+?[\d]+$/;

  const handleCheck = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors("");

    const inputValue = mobileNumber;

    if (emailRegex.test(inputValue)) {
      setErrors("");
      await handleSubmitemail(inputValue);

    } else if (mobileRegex.test(inputValue)) {
      console.log("mobile")
      setErrors("");
      await handleSubmit(event);

    } else {
      setErrors("Please enter a valid mobile number or email.");
    }
    setLoading(false);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      handleCheck(event);
    }
  };

  const handleloginEmail = () => {
    setMobileOrEmail('email')
  }

  const handleloginmobile = () => {
    setMobileOrEmail('mobile')
  }


  // otpModal

  // useEffect(() => {
  //   if (openOTPModal) {
  //     setCountdown(60);
  //   }


  //   const timer = countdown > 0 && setInterval(() => {
  //     setCountdown((prevCountdown) => prevCountdown - 1);
  //   }, 1000);


  //   return () => clearInterval(timer);
  // }, [openOTPModal]);
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevTime) => prevTime - 1);
      }, 1000);

      // Cleanup interval on component unmount or when timeLeft changes
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const validatePhoneNumber = (mobileNumber) => {
    return /^(0|\+94|94)?[0-9]{9}$/.test(mobileNumber);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!mobileNumber) {
      setErrors("Please enter a Mobile number");
    } else if (!validatePhoneNumber(mobileNumber)) {
      setErrors("Invalid mobile number. Please enter a valid mobile number.");
    } else if (!(mobileNumber.length <= 14)) {
      setErrors("Invalid length");
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/student_login`,
          {
            method: "POST",
            body: JSON.stringify({
              mobileNumber,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );



        if (response.status === 403) {
          handleCloseMobileNumberModal(true);
          const data = await response.json();
          Swal.fire({
            icon: 'error',
            title: data.title,
            text: data.message,
            confirmButtonColor: '#00669e',
            confirmButtonText: 'OK',
            customClass: {
              container: 'custom-swal-container'
            }
          });
          return;
        }

        if (!response.ok) {
          throw new Error(`HTTP error: Status ${response.status}`);
        }

        const data = await response.json();

        if (
          data.message === "OTP updated successfully" ||
          data.message === "Mobile number and OTP inserted successfully"
        ) {
          setOpenMobileNumberModal(false);
          handleOpenOTPModal();
          setStudentID(data.stu_id);
          setErrors("");
        } else if (data.message == "Invalid") {
          setErrors("Invalid Phone Number");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      handleValue(event);
    }
  };

  const handleOpenOTPModal = () => {
    setCountdown(60)
    setOpenOTPModal(true);
    setTimeout(() => {
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, 0);
  };

  const handleCloseOTPModal = () => {
    setOpenOTPModal(false);
    setErrors("");
    setMobileNumber("");
  };


  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const setCaretToEnd = (element) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
    element.focus();
  };

  // const handleInput = (index, event) => {
  //   const value = event.target.innerText.replace(/[^0-9]/g, "");
  //   const newOTP = [...otp];
  //   newOTP[index] = value.slice(-1);
  //   setOTP(newOTP);


  //   event.target.innerText = value.slice(-1);


  //   if (value && index < otp.length - 1) {
  //     inputRefs.current[index + 1].focus();
  //   } else {
  //     setCaretToEnd(event.target);
  //   }
  // };

  // const handleKeyDown = (index, event) => {
  //   if (
  //     event.key === "Backspace" &&
  //     !otp[index] &&
  //     index > 0 &&
  //     inputRefs.current[index - 1]
  //   ) {
  //     inputRefs.current[index - 1].focus();
  //     setCaretToEnd(inputRefs.current[index - 1]);
  //   }
  // };

  const handleInput = (event, index) => {
    const value = event.target.value;

    // Check if the value is a valid number
    if (/^\d+$/.test(value)) {
      const newOtp = [...otp];

      // Extract only the last digit of the input
      newOtp[index] = value.slice(-1);

      // Update the OTP state with the new value
      setOTP(newOtp);

      // Focus the next text field if available
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      } else {
        setCaretToEnd(event.target);
      }
    }
  };



  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" || event.key === "Cancel") {
      const newOtp = [...otp];
      if (otp[index]) {
        // Clear current field
        newOtp[index] = '';
        setOTP(newOtp);
      } else if (index > 0) {
        // Move to the previous field if empty
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleClick = (index) => {
    const newOtp = [...otp];  // Create a copy of the OTP array
    newOtp[index] = "";       // Clear the value of the clicked field
    setOTP(newOtp);
    inputRefs.current[index].focus();
    setFocusedIndex(index)

  };

  const handleValue = async () => {
    setErrors("");
    setLoading(true);
    try {
      if (!otp) {
        setErrors("Please enter OTP.");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otp`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          otp: otp.join(""),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // if (!response.ok) {
      //   throw new Error(`HTTP error: Status ${response.status}`);
      // }

      const data = await response.json();

      if (response.status === 200) {
        setOTP(new Array(4).fill(""));
        setOpenOTPModal(false);

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)
        handlepushNotification(data.accessToken)

        handleCloseOTPModal();

        // check neew user or not
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${data.accessToken}`
          },
        });


        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (!studentData.stu_fname || !studentData.stu_lname || !studentData.email || !studentData.address || !studentData.city || !studentData.country) {
          // handleOpenProfileModal();
          navigate('/community_knowledge');

        } else {
          navigate("/community_knowledge");
        }

      } else {
        setErrors(data.error);
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setErrors("An error occurred while verifying OTP.");
    }
    setLoading(false);
  };

  const handleChange = () => {
    setOpenOTPModal(false);
    setOpenMobileNumberModal(true);
  };

  const handleSend = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobileNumber }),
      });

      const data = await response.json();

      if (response.ok) {

        setOpenOTPModal(true);

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: data.message || 'Failed to generate OTP. Please try again.',
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
      }
    } catch (error) {

    }
  };

  const handleLoginSignup = async () => {
    console.log("Login successful!");
    handleCloseMobileNumberModal();
    setOpenOTPModal(true);
  };

  const handleOTPVerify = async () => {
    console.log("Login successful!");

    window.location.href = "/community_knowledge";

  };


  //Password Model

  const handleOpenPasswordModal = () => {
    setOpenPasswordModal(true);

  };

  const handleSubmitemail = async (event) => {

    if (event && event.preventDefault) {
      event.preventDefault();
    }

    if (!mobileNumber) {
      setEmailError("Please enter an email");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/student_loginwithemail`,
        {
          method: "POST",
          body: JSON.stringify({ mobileNumber }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 403) {
        const data = await response.json();
        Swal.fire({
          icon: 'error',
          title: data.title,
          text: data.message,
          confirmButtonColor: '#00669e',
          confirmButtonText: 'OK',
          customClass: {
            container: 'custom-swal-container'
          }
        });
        return;
      }

      const data = await response.json();

      if (response.status === 404) {
        setEnterPassword('emailpassowrd')
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();

      }

      if (response.status === 400) {
        setEnterPassword("set")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
      }

      if (response.status === 200) {
        setEnterPassword("enter")
        setOpenMobileNumberModal(false);
        handleOpenPasswordModal();
        setStudentID(data.stu_id);
        setEmailError("");
      }

    } catch (error) {
      console.error("Error fetching data: ", error);
      setEmailError("An error occurred while trying to log in. Please try again later.");
    }
  };


  const handleClosePasswordModal = () => {
    setOpenPasswordModal(false);
    setErrors("");
    setErrors2("");
    setPassword("");
    setNewPassword("");
    setEmailpassword("");
    setMobileNumber("");
  };

  const handlepassword = (event) => {
    setPassword(event.target.value);
    setErrors("")
  };

  const handleEnterPassword = (event) => {
    if (event.key === "Enter") {
      handleNext(event);
    }
  };

  const handleNext = async () => {
    setErrors2("");
    setLoading(true);
    try {
      if (!password) {
        setErrors2("Please enter the password");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student_verify_password`, {
        method: "POST",
        body: JSON.stringify({
          studentID,
          mobileNumber,
          password
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });


      const data = await response.json();

      if (response.status === 200) {
        handleClosePasswordModal();

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)
        
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: data.studentID }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();

        if (studentData.temp == 0) {
          // setOpenUserDetailsEmail(true);
          navigate('/community_knowledge');
        } else {
          navigate('/community_knowledge');
        }

      } else if (response.status === 404) {
        setLoading(false);
        setErrors1(data.message);
      } else {
        setLoading(false);
        setErrors1(data.message);
      }
    } catch (error) {
      setErrors("An error occurred ");
    }
    setLoading(false);
  };

  const handleEnterSetPassword = (event) => {
    if (event.key === "Enter") {
      handleNewPasswordNextSubmit(event);
    }
  };

  const handleNewPasswordNextSubmit = async (event) => {

    event.preventDefault();

    setErrors1("");

    if (!newPassword) {
      setErrors1("Please enter a password");
      return
    }

    if (newPassword.length < 6) {
      setErrors1("The password must be equal or more than 6 characters.");
      return
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            studentID,
            mobileNumber,
            newPassword
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {
        setOpenPasswordModal(false);
        setNewPasswordError("");

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)

        handlepushNotification(data.accessToken)

      }
      const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
        method: "POST",
        body: JSON.stringify({ studentID: data.studentID }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!studentResponse.ok) {
        throw new Error(`HTTP error: Status ${studentResponse.status}`);
      }

      const studentData = await studentResponse.json();

      if (studentData.temp == 0) {
        // setOpenUserDetailsEmail(true);
        navigate('/community_knowledge');
      } else {
        navigate("/community_knowledge");
      }
    } catch (error) {
      setErrors1(error.message);
    }
    setLoading(false);
  };

  const handleEnterSetPasswordBoth = (event) => {
    if (event.key === "Enter") {
      handleEnterEmailPassword(event);
    }
  };

  const handleEnterEmailPassword = async () => {
    setErrors1("");
    if (!emailpassword) {
      setErrors1("Please enter a password");
      return
    }

    if (emailpassword.length < 6) {
      setErrors1("The password must be equal or more than 6 characters.");
      return
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/NewEmailPassword`,
        {
          method: "POST",
          body: JSON.stringify({
            mobileNumber,
            emailpassword
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

      if (!response.ok) {
        throw new Error(`HTTP error: Status ${response.status}`);
      }

      const data = await response.json();
      if (response.status === 200) {

        window.localStorage.setItem('studentID', data.studentID)
        window.localStorage.setItem('token', data.accessToken)
        window.localStorage.setItem('refreshToken', data.refreshToken)
        handlepushNotification(data.accessToken)
        setOpenPasswordModal(false);
        // setOpenUserDetailsEmail(true);
        navigate('/community_knowledge');
      }

    } catch (error) {
      console.errors2("Error fetching data: ", error);
      setErrors1(error.message);
    }
  }

  const handleChangeEmailPassword = () => {
    setOpenPasswordModal();
    setOpenMobileNumberModal(true);
  };

  //Forgot Password Modal

  const handleChangeForgotPassword = () => {
    setOpenPasswordModal();
    navigate(`/forgot_password?semail=${JSON.stringify(mobileNumber)}`)
  };


  //Profile Model

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  };

  const handlefirstname = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastname = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handleemail = (event) => {
    setEmail(event.target.value);
    setErrors("")
  };

  const handleaddress = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecity = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountry = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
  };

  const handleCloseProfileModal = () => {
    setOpenProfileModal(false);
    setErrors("");
  };

  const handleCloseUserDetailsEmail = () => {
    setOpenUserDetailsEmail(false);
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();


    let newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = "Invalid email address";
    }

    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!email) {
      newErrors.email = "Please enter the Email";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');
      setLoading1(true);

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details`, {
          method: 'POST',
          body: JSON.stringify({
            studentID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            email,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`

          }
        });

        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {

              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfile(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setLoading1(false);
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setEmail("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModal();
          navigate('/community_knowledge');
        } else {
          setLoading1(false);
          setErrors({ ...errors, email: "This email is already registered. Please use another email." });
        }

      } catch (error) {
        setLoading1(false);
        setErrors("Failed to update profile. Please try again.");
      }

    }
  };



  //ProfileEmail Model


  const handlefirstnameEmail = (event) => {
    setStu_fname(event.target.value);
    setErrors("")
  };

  const handlelastnameEmail = (event) => {
    setStu_lname(event.target.value);
    setErrors("")
  };

  const handlePhone = (event) => {
    let valuemob = event.target.value;
    setErrors({ phone: "" }); // Initialize the errors state
    setMobileError("");

    if (!valuemob) {
      setErrors({ phone: "" }); // Clear errors if input is empty
    } else if (!/^\+?[\d]+$/.test(valuemob)) {
      setErrors({ phone: "Invalid mobile number. Only digits and an optional '+' are allowed." });
    } else {
      if (valuemob.startsWith("7")) {
        if (valuemob.length !== 9) {
          setErrors({ phone: "Invalid mobile number. Must be 9 digits when starting with '7'." });
        }
      } else if (valuemob.startsWith("07")) {
        if (valuemob.length !== 10) {
          setErrors({ phone: "Invalid mobile number. Must be 10 digits when starting with '07'." });
        }
      } else if (valuemob.startsWith("947")) {
        if (valuemob.length !== 11) {
          setErrors({ phone: "Invalid mobile number. Must be 11 digits when starting with '947'." });
        }
      } else if (valuemob.startsWith("+947")) {
        if (valuemob.length !== 12) {
          setErrors({ phone: "Invalid mobile number. Must be 12 digits when starting with '+947'." });
        }
      } else {
        setErrors({ phone: "Invalid mobile number format." });
      }
    }

    setPhone(valuemob);
  };

  const handleaddressEmail = (event) => {
    setAddress(event.target.value);
    setErrors("")
  };

  const handlecityEmail = (event) => {
    setCity(event.target.value);
    setErrors("")
  };

  const handlecountryEmail = (event, newValue) => {
    setCountry(newValue);
    setErrors("")
  };

  const handleCloseProfileModalEmail = () => {
    setOpenUserDetailsEmail(false);
    setErrors("");
  };

  const capitalizeFirstLetterEmail = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmitProfileEmail = async (event) => {
    event.preventDefault();

    let newErrors = {};


    if (!stu_fname) {
      newErrors.stu_fname = "Please enter the First Name";
    }

    if (!stu_lname) {
      newErrors.stu_lname = "Please enter the Last Name";
    }

    if (!phone) {
      newErrors.phone = "Please enter the Mobile Number";
    }

    if (!address) {
      newErrors.address = "Please enter the Address";
    }

    if (!city) {
      newErrors.city = "Please enter the City";
    }

    if (!country) {
      newErrors.country = "Please enter the Country";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors('');
      setLoading1(true);

      // Capitalize names
      const capitalizedFirstName = capitalizeFirstLetter(stu_fname);
      const capitalizedLastName = capitalizeFirstLetter(stu_lname);
      const capitalizedAddress = capitalizeFirstLetter(address);
      const capitalizedCity = capitalizeFirstLetter(city);
      const capitalizedCountry = capitalizeFirstLetter(country);

      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/profile_details_Email`, {
          method: 'POST',
          body: JSON.stringify({
            studentID: studentsID,
            stu_fname: capitalizedFirstName,
            stu_lname: capitalizedLastName,
            phone,
            address: capitalizedAddress,
            city: capitalizedCity,
            country: capitalizedCountry
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });


        if (response.status === 403 || response.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            console.log("object")
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;
              return handleSubmitProfileEmail(event);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        const data = await response.json();

        if (response.status === 200) {
          setLoading1(false);
          if (window.gtag_report_conversion) {
            console.log("check script")
            window.gtag_report_conversion();
          } else {
            console.warn("gtag_report_conversion function is not defined.");
          }
          setStu_fname("");
          setStu_lname("");
          setPhone("");
          setAddress("");
          setCity("");
          setCountry("");
          setErrors('');
          handleCloseProfileModalEmail();
          navigate('/community_knowledge');

        } else if (response.status === 400) {
          setLoading1(false);
          setMobileError(data.message);
        }

      } catch (error) {
        setLoading1(false);
        setErrors("Failed to update profile. Please try again.");
      }
    }
  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon",
    "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
    "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
    "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea",
    "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia",
    "Iran", "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan",
    "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
    "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
    "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro",
    "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand",
    "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
    "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
    "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles",
    "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
    "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria",
    "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia",
    "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom",
    "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const handleButtonClickAsk = async (id) => {
    setT_id(id);
    const sessionID = localStorage.getItem('studentID');

    if (sessionID) {
      try {
        const studentResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/student`, {
          method: "POST",
          body: JSON.stringify({ studentID: sessionID }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
        });

        if (studentResponse.status === 403 || studentResponse.status === 401) {
          let refreshToken = '';
          if (typeof localStorage !== 'undefined') {
            refreshToken = window.localStorage.getItem('refreshToken');
          }

          try {
            const tokenResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            });

            if (tokenResponse.status === 200) {
              const tokendata = await tokenResponse.json();
              window.localStorage.setItem('token', tokendata.accessToken);
              token = tokendata.accessToken;


              return handleButtonClickAsk(id);
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error('Error refreshing token:', error);
            handleLogout();
          }

          return;
        }

        if (!studentResponse.ok) {
          throw new Error(`HTTP error: Status ${studentResponse.status}`);
        }

        const studentData = await studentResponse.json();
        console.log(studentData);
        if (studentData.temp == 0 && studentData.phone_num) {
          setOpenProfileModal(true);
        } else if (studentData.temp == 0 && studentData.email) {
          setOpenUserDetailsEmail(true);
        }

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    } else {
    }
  };

  const handlecheckid = () => {
    { localStorage.getItem('studentID') ? handleButtonClickAsk() : handleOpenMobileNumberModal() }
  };

  const handleEnterKey1 = (event) => {

    handleValue(event);

  };

  const handleKeyDown1 = () => {
    const newOtp = [...otp];
    if (otp[focusedIndex]) {
      // Clear current field
      newOtp[focusedIndex] = '';
      setOTP(newOtp);
    } else if (focusedIndex > 0) {
      // Move to the previous field if empty
      inputRefs.current[focusedIndex - 1].focus();
      setFocusedIndex(focusedIndex - 1)
    }

  };

  const handleInput1 = (key) => {
    const value = key;

    // Check if the value is a valid number
    const newOtp = [...otp];

    // Extract only the last digit of the input
    newOtp[focusedIndex] = value

    // Update the OTP state with the new value
    setOTP(newOtp);

    // Focus the next text field if available
    if (value && focusedIndex < otp.length - 1) {
      inputRefs.current[focusedIndex + 1].focus();
      setFocusedIndex(focusedIndex + 1)
    }

  };

  const fetchSocialLinks = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/system-setting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });


      const data = await response.json();
      if (response.status == 200) {
        // Update the state with the links
        setHotline(data.result.contact_num ? data.result.contact_num : '');
      } else {
        console.warn('No data found:', data.message);
      }
    } catch (err) {
      console.error('Failed to fetch system settings:', err);
    }
  };

  return (
    <div>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>

        <Typography variant='h5' >
          Knowledge & Community
        </Typography>

      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%', backgroundColor: "" }}>

          <Card sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px', }}>
            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography onClick={handlecheckid} sx={{ flexGrow: 1, border: "1px solid #4c4e64de", borderRadius: '20px', p: 1.5, fontWeight: 500, cursor: 'pointer' }}>Ask a question</Typography>
            </CardContent>
          </Card>


          {tableData.map((post, index) => (
            <Card key={index} sx={{ width: '100%', boxShadow: 'rgba(76, 78, 100, 0.22) 0px 2px 10px 0px', border: '1px solid rgba(76, 78, 100, 0.12)', borderRadius: '10px' }}>
              <CardContent sx={{ p: '15px' }}>

                <ForumCard post={post} handleCommentClick={handleCommentClick} />

              </CardContent>
            </Card>
          ))}

          {hasMore && (
            <Grow in={true} timeout={500}>
              <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                {isLoading && <CircularProgress />}
              </Box>
            </Grow>
          )}


        </Box>

        <Box
          sx={{
            backgroundColor: '',
            maxWidth: '300px',
            width: '100%',
            display: { xs: 'none', md: 'block' }
          }}
        >
          {banner.slice(0, 1).map((data, index) => (
            <img
              key={index}
              src={data.url}
              alt='post'
              style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: '2px' }} // Ensure image fills the container
            />
          ))}

          <Box sx={{
            display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '300px',
            width: '300px', mb: 1
          }}>
            {randomTeacherP.map((data, index) => (
              <RandomTeachers teacher={data} key={index} />
            ))}
          </Box>

          <Box sx={{
            maxWidth: '300px',
            width: '300px',
            mb: 1,
            position: 'sticky',
            top: 80,
            maxHeight: `calc(100vh - 400px)`
          }}>
            <Box>
              {otherBanner.map((data, index) => (
                <img
                  key={index}
                  src={data.url}
                  alt='post'
                  style={{ width: '100%', height: '300px', objectFit: 'contain', }} // Ensure image fills the container
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <CommentPopup setCommentOpen={setCommentOpen} commentOpen={commentOpen} post={forumObject} isLoadingComments={isLoadingComments} subComments={subComments} setSubComments={setSubComments} handleCommentPage={handleCommentPage} setSubPage={setSubPage} totalComments={totalComments} />

      <Dialog open={openMobileNumberModal} onClose={handleCloseMobileNumberModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2.5,
          }}
        >
          <DialogTitle>Login/Signup</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseMobileNumberModal}
            />
          </Tooltip>
        </Grid>

        <DialogContent>
          <DialogContentText sx={{ pb: 1 }}>
            Please Enter Your Mobile Number or Email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="mobileNumber"
            label="Mobile Number/Email"
            type="text"
            fullWidth
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            onKeyDown={handleEnter}
            error={!!errors}
            helperText={errors}
            placeholder="07XXXXXXXX / XXXX@XX.XX"
            inputProps={{ autoComplete: 'off' }}
          />



        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>

          <Button
            sx={{
              size: 'large',
              type: "submit",
              display: "flex",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0d2353",
              "&:hover": {
                backgroundColor: "#0d65c8",
              },
              "&.Mui-disabled": {
                backgroundColor: "#5BB4E4",
                color: "white",
              },
              variant: "contained",
              borderRadius: "6px",
              marginBottom: "25px",
              px: 3,
            }}
            onClick={handleCheck}
            disabled={loading}
            startIcon={loading ? (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                  color: '#fff'
                }} />
            ) : null}
          >
            {loading ? "Please Wait" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOTPModal} onClose={handleCloseOTPModal} fullWidth
        maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>OTP Verification</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleCloseOTPModal}
            />
          </Tooltip>
        </Grid>

        <DialogContent>
          <DialogContent sx={{ borderRadius: "6px" }}>
            <DialogContentText
              sx={{
                textAlign: "center",
                varient: "modal-body",
                color: "black",
                mb: 1,
              }}
            >
              Please enter the OTP to verify your account
            </DialogContentText>
            <DialogContentText sx={{ mb: "1", textAlign: "center" }}>
              Code has been sent to {mobileNumber}
              <span
                style={{
                  marginLeft: 5,
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChange}
              >
                Change
              </span>
            </DialogContentText>

            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                flexDirection: "row",
                marginTop: "20px",
                gap: 7
              }}
            >
              {lgAbove ? (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        // readOnly: true, 
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              ) : (
                <>
                  {otp.map((digit, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (inputRefs.current[index] = el)}
                      value={digit}
                      onChange={(e) => handleInput(e, index)}
                      onClick={() => handleClick(index)}
                      onKeyDown={(e) => {
                        handleKeyDown(index, e);
                        handleEnterKey(e);
                      }}

                      inputProps={{
                        inputMode: 'none',
                        readOnly: true,
                        pattern: "[0-9]*",
                      }}

                      sx={{
                        width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                          height: 40, // Ensures the input height
                          display: "flex",
                          alignItems: "center",
                          zIndex: 1600,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid gray", // Adjust border
                        },
                        "& .MuiInputBase-input": {
                          padding: 0, // Remove inner padding for precise size
                          textAlign: "center", // Centers the text
                        },
                      }}
                    />
                  ))}
                </>
              )}

              {/* {otp.map((digit, index) => (
                <TextField
                  key={index}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleInput(e, index)}
                  onClick={() => handleClick(index)}
                  onKeyDown={(e) => {
                    handleKeyDown(index, e);
                    handleEnterKey(e);
                  }}
                  inputProps={{
                    inputMode: 'none', // Show numeric keypad on mobile
                    // readOnly: true,
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    width: 40, height: 40, textAlign: 'center', padding: '0px', "& .MuiInputBase-root": {
                      height: 40, // Ensures the input height
                      display: "flex",
                      alignItems: "center",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid gray", // Adjust border
                    },
                    "& .MuiInputBase-input": {
                      padding: 0, // Remove inner padding for precise size
                      textAlign: "center", // Centers the text
                    },
                  }}
                />
              ))} */}

            </Grid>
            {errors && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <Typography
                  variant="caption"
                  sx={{ color: "red", textAlign: "center", fontSize: "14px" }}
                >
                  {errors}
                </Typography>
              </Box>
            )}

            <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#F1F1F1', borderRadius: 1, mt: 2, display: { xs: 'block', lg: 'none' } }}>
              <Grid container spacing={1}>
                {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Clear'].map((key) => (
                  <Grid item xs={key === 'Clear' ? 8 : 4} key={key}>
                    <Button
                      variant="contained"
                      // color={key === 'Clear' ? 'error' : key === 'Enter' ? 'success' : 'primary'}
                      sx={{ backgroundColor: '#cfd9ddd1', ":hover": { backgroundColor: '#cfd9ddd1' }, color: '#413e3e', fontSize: '16px', minHeight: '40px' }}
                      fullWidth
                      onClick={() =>
                        key === 'Clear' ? handleKeyDown1() : key === 'Enter' ? handleEnterKey1() : handleInput1(key)
                      }
                    >
                      {key === 'Clear' ? <Icon icon="material-symbols-light:backspace" width="24" height="24" /> : key === 'Enter' ? <Icon icon="fluent:arrow-enter-left-24-regular" width="24" height="24" style={{ color: 'black' }} /> : key}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <DialogActions sx={{ justifyContent: "center" }}>

              <Button
                sx={{
                  type: "submit",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  mt: 2,
                  px: 4,
                  py: 1,
                  borderRadius: "6px",
                }}
                onClick={handleValue}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Verify"}
              </Button>
            </DialogActions>
          </DialogContent>
          <DialogContentText sx={{ my: 2, justifyContent: "center", textAlign: "center" }}>
            Didn't get the code?
            {countdown > 0 ? (
              <span style={{ marginLeft: 5, color: "gray" }}> Try Again in {countdown}s</span>
            ) : (
              <span
                style={{ marginLeft: 5, color: "rgb(13 110 253)", cursor: "pointer" }}
                onClick={() => {
                  handleSend();
                  setCountdown(60);
                }}
              >
                Try Again
              </span>
            )}
          </DialogContentText>
        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>

      <Dialog open={openPasswordModal} onClose={handleClosePasswordModal} fullWidth maxWidth='sm'>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "linear-gradient(to right , #101847, #00669e)",
            color: "white",
            mb: 2,
          }}
        >
          <DialogTitle>{enterPassword == "enter" ? "Enter Your Password" : "Set Your Password"}</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon
              sx={{ mt: 2, mr: 2, cursor: "pointer" }}
              onClick={handleClosePasswordModal}
            />
          </Tooltip>
        </Grid>

        {enterPassword == "enter" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Enter Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              autoFocus
              type={showPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={password}
              onChange={handlepassword}
              onKeyDown={handleEnterPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <DialogContentText sx={{ textAlign: "right", mt: 2 }}>
              <span
                style={{
                  color: "rgb(13 110 253)",
                  cursor: "pointer",
                }}
                onClick={handleChangeForgotPassword}
              >
                Forgot Password ?
              </span>
            </DialogContentText>

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  type: "submit",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2,
                  px: 3
                }}
                onClick={handleNext}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "set" ? (
          <DialogContent>

            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              autoFocus
              placeholder="Password"
              type={shownewPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              onKeyDown={handleEnterSetPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowNewPassword(!shownewPassword)}
                      edge="end"
                    >
                      {shownewPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                sx={{
                  type: "submit",
                  display: "flex",
                  justifyContent: "center",
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  variant: "contained",
                  borderRadius: "6px",
                  mt: 2
                }}
                onClick={handleNewPasswordNextSubmit}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                {loading ? "Please Wait" : "Next"}
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : enterPassword == "emailpassowrd" ? (
          <DialogContent>
            <Typography>
              Email - {mobileNumber}
            </Typography>
            <DialogContentText sx={{ pb: 1 }}>
              Please Set Your Password
            </DialogContentText>
            <TextField
              id="password"
              name="password"
              placeholder="Password"
              autoFocus
              type={showemailPassword ? 'text' : 'password'}
              size="large"
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px !important',
              }}
              fullWidth
              error={!!errors1}
              helperText={errors1}
              value={emailpassword}
              onChange={(event) => setEmailpassword(event.target.value)}
              onKeyDown={handleEnterSetPasswordBoth}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowemailPassword(!showemailPassword)}
                      edge="end"
                    >
                      {showemailPassword ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <DialogActions sx={{ justifyContent: "center" }}>

              <Button
                component="div"
                sx={{
                  color: "white",
                  backgroundColor: "#0d2353",
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#5BB4E4",
                    color: "white",
                  },
                  borderRadius: "6px",
                  padding: "8px 18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "8px",
                  cursor: "pointer",
                  mt: 2,
                  "&:hover": {
                    backgroundColor: "#0d65c8",
                  },
                  "&:hover .icon": {
                    transform: "translateX(5px)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={handleEnterEmailPassword}
                disabled={loading}
                startIcon={loading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                      color: '#fff'
                    }} />
                ) : null}
              >
                <Box component="span" sx={{ display: "inline-flex", alignItems: "center", gap: "8px" }}>
                  {loading ? "Please Wait" : "Register"}
                  <Icon className="icon" icon="solar:round-arrow-right-bold" width="1.5rem" height="1.5rem" />
                </Box>
              </Button>
            </DialogActions>
            <DialogContentText sx={{ mt: 1, textAlign: "center" }}>
              <span
                style={{
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={handleChangeEmailPassword}
              >
                Back to login
              </span>
            </DialogContentText>
          </DialogContent>
        ) : null}

      </Dialog>

      <Dialog open={openProfileModal} onClose={handleCloseProfileModal} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems: 'center' }}>
          <DialogTitle sx={{ ml: 1, fontSize: { xs: '17px', sm: '20px' } }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mr: 3, cursor: 'pointer' }} onClick={handleCloseProfileModal} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfile}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstname}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastname}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={email}
                  onChange={handleemail}
                />
                {errors.email ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.email}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddress}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecity}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  value={country}
                  onChange={handlecountry}
                  options={countries}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  ListboxProps={{
                    sx: {
                      maxHeight: '200px', // Set height for dropdown content
                      overflowY: 'auto', // Enable scrolling only inside the list
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item
                mb={1}
                mt={3}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#0d2353",
                    "&:hover": {
                      backgroundColor: "#0d65c8",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#5BB4E4",
                      color: "white",
                    },
                  }}
                  size="md"
                  disabled={loading1}
                  startIcon={loading1 ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        color: '#fff'
                      }} />
                  ) : null}
                >
                  {loading1 ? "Please Wait" : "Sign up"}
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>

      <Dialog open={openUserDetailsEmail} onClose={handleCloseUserDetailsEmail} fullWidth>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'black', alignItems: 'center' }}>
          <DialogTitle sx={{ ml: 1, fontSize: { xs: '17px', sm: '20px' } }} >Please Enter your Details</DialogTitle>
          <Tooltip title="Close">
            <CloseIcon sx={{ mr: 3, cursor: 'pointer' }} onClick={handleCloseUserDetailsEmail} />
          </Tooltip>
        </Box>

        <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
          <form

            style={{
              width: "100%",
              backgroundColor: "linear-gradient(to right , #101847, #00669e",
              color: "white"
            }}
            onSubmit={handleSubmitProfileEmail}

          >
            <Grid container spacing={1} sx={{ px: 1 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="stu_fname"
                  name="firstName"
                  label="First Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",


                  }}
                  fullWidth
                  value={stu_fname}
                  onChange={handlefirstnameEmail}

                />
                {errors.stu_fname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_fname}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={stu_lname}
                  onChange={handlelastnameEmail}

                />
                {errors.stu_lname ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.stu_lname}
                  </Typography> : null
                }

              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Mobile Number"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={phone}
                  onChange={handlePhone}
                />
                {errors.phone ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.phone}
                  </Typography> : null
                }
                {mobileError ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {mobileError}
                  </Typography> : null
                }
              </Grid >

              <Grid item xs={12}>
                <TextField
                  id="address"
                  name="House No, Lane Name"
                  label="House No, Lane Name"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",
                    mt: 1

                  }}
                  fullWidth
                  value={address}
                  onChange={handleaddressEmail}

                />
                {errors.address ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.address}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="city"
                  name="City"
                  label="City"
                  size="large"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "10px !important",

                  }}
                  fullWidth
                  value={city}
                  onChange={handlecityEmail}

                />
                {errors.city ?
                  <Typography variant="caption" display="block" sx={{ color: 'red', mt: 1 }}>
                    {errors.city}
                  </Typography> : null
                }
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  options={countries}
                  value={country}
                  onChange={handlecountry}
                  sx={{ width: "100%" }}
                  PopperComponent={CustomPopper} // Custom Popper to position dropdown above
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country"
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "10px !important",
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item
                mb={1}
                mt={3}
                xs={12}
                sx={{

                  display: "flex",
                  justifyContent: "center",
                }}
              >

                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "#0d2353",
                    "&:hover": {
                      backgroundColor: "#0d65c8",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#5BB4E4",
                      color: "white",
                    },
                  }}
                  size="md"
                  disabled={loading1}
                  startIcon={loading1 ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        color: '#fff'
                      }} />
                  ) : null}
                >
                  {loading1 ? "Please Wait" : "Sign up"}
                </Button>

              </Grid>
            </Grid>
          </form>

        </DialogContent>

        <Typography sx={{ textAlign: 'center', fontSize: 16 }}>
          Need a help?
        </Typography>

        <Box sx={{ mb: 4, fontSize: 12 }}>
          <a href={`tel:${hotline}`} style={{ textDecoration: 'none', textAlign: 'center', color: '#0d2657' }}>

            <Typography size="large" sx={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              HOTLINE {' '}
              <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', ml: 2, mr: 1 }}>
                <Icon icon="ic:baseline-phone" width="18" height="18" />
              </Box>
              {hotline}
            </Typography>
          </a>
        </Box>
      </Dialog>
    </div>
  )
}


export default ForumMain
